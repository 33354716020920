import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import TrafficLight from "../../common/components/TrafficLight";
import { mapFilterSlice } from "../../data/mapFilterSlice";
import { MapFilter } from "../../types/MapFilter";
import { SamplingData } from "../../types/SamplingData";

type DataItemProps = {
  data: SamplingData;
  dispatcher: ThunkDispatch<any, any, any>;
  mapFilter: MapFilter;
  setShow: React.Dispatch<
    React.SetStateAction<{
      display: boolean;
      locationId?: number | undefined;
      name?: string | undefined;
      lat?: number | undefined;
      lon?: number | undefined;
    }>
  >;
};
export const DataItem: React.FC<DataItemProps> = (props) => {
  return (
    <Flex
      key={props.data.id}
      height="2rem"
      border="solid 1px"
      borderColor="gray.200"
      borderRadius="0.25rem"
      padding="0.5rem"
      marginBottom="0.5rem"
      alignItems="center"
      justifyContent="space-between"
      _hover={{ cursor: "pointer", backgroundColor: "gray.200" }}
      onClick={() => {
        const newDate = props.data.inspectionDate;
        props.dispatcher(
          mapFilterSlice.actions.set({
            sector: props.mapFilter.sector,
            section: props.mapFilter.section,
            inspectionDate: newDate,
            categories: props.mapFilter.categories,
            qualities: props.mapFilter.qualities,
            filteredSamplingData: props.mapFilter.filteredSamplingData,
            refetch: props.mapFilter.refetch,
          })
        );
        props.setShow({
          display: false,
          locationId: undefined,
          name: undefined,
          lat: undefined,
          lon: undefined,
        });
      }}
    >
      <Flex alignItems="center">
        <TrafficLight
          color={
            props.data.quality === "Bad"
              ? "primary.light"
              : props.data.quality === "Medium"
              ? "yellow.light"
              : "green.light"
          }
        />
        <Text marginLeft="0.5rem">
          {DateTime.fromSeconds(props.data.inspectionDate).toFormat(
            "dd LLL yyyy"
          )}
        </Text>
      </Flex>
      <ArrowForwardIcon height="1rem" width="1rem" color="gray.500" />
    </Flex>
  );
};
