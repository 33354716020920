import { FormType } from "./FormType";

export const initialForm: FormType = {
  id: undefined,
  laboratoryId: "",
  inspectionDate: new Date(),
  laboratoryDate: new Date(),
  coordinates: { lat: "0", lon: "0" },
  comment: "",
  constructionWorksComment: "",
  airTemperature: "",
  windSpeed: "",
  airHumidity: "",
  airPressure: "",
  precipitation: "",
  images: [],
  class: "",
  quality: "Good",
  typeOfMonitoring: "regular",
  parameters: [],
};
