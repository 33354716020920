import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { api } from "./api";
import { appViewSlice } from "./appViewSlice";
import { authSlice } from "./authSlice";
import { configSlice } from "./configSlice";
import { detailViewSlice } from "./detailViewSlice";
import { mapFilterSlice } from "./mapFilterSlice";
import { newSamplingDataViewSlice } from "./newSamplingDataViewSlice";
import { parameterSchemaSlice } from "./parameterSchemaSlice";
import { uploadApiAWS } from "./uploadApiAWS";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    appView: appViewSlice.reducer,
    auth: authSlice.reducer,
    config: configSlice.reducer,
    detailView: detailViewSlice.reducer,
    mapFilter: mapFilterSlice.reducer,
    newSamplingDataView: newSamplingDataViewSlice.reducer,
    parameterSchema: parameterSchemaSlice.reducer,
    [uploadApiAWS.reducerPath]: uploadApiAWS.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["location/setRefetch", "mapFilter/set"],
        ignoredPaths: ["location.refetch", "mapFilter.value.refetch"],
      },
    }).concat([api.middleware, uploadApiAWS.middleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
