import React from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

type PropsType = {
  color: string;
  borderColor?: string;
  percentage?: number;
  current?: number;
  total?: number;
};

export const ProgressBar: React.FC<PropsType> = (props: PropsType) => {
  const { color, borderColor, percentage, current, total } = props;
  const [currentPercentage, setCurrentPercentage] = React.useState<number>(0);

  React.useEffect(() => {
    if (percentage && percentage !== 0) {
      setCurrentPercentage(percentage);
    } else if (current && total && current !== 0 && total !== 0) {
      setCurrentPercentage((current / total) * 100);
    } else {
      setCurrentPercentage(0);
    }
  }, [percentage, current, total]);

  return (
    <Flex alignItems="center" w="100%" h="0.875rem" overflow="hidden">
      <Box
        w={`${currentPercentage}%`}
        h="100%"
        bg={color}
        borderRadius="2px"
        {...(borderColor && {
          border: "1px solid",
          borderColor,
        })}
      />
      <Text
        marginLeft={"0.5rem"}
        textStyle="body3"
        fontWeight="bold"
        color="gray.800"
      >
        {current || parseInt(currentPercentage + "")}
      </Text>
    </Flex>
  );
};
