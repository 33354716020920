import { useState, useEffect } from "react";
import { api } from "../data/api";
import { AuthState } from "../data/authSlice";
import { MapFilter } from "../types/MapFilter";
import { SamplingLocation } from "../types/SamplingLocation";
import { imageMapping } from "./imageMapping";
import { getQualityColor } from "./getQualityColor";
import { MarkerType } from "./MarkerType";

export const useMapboxComponent = (mapFilter: MapFilter, auth: AuthState) => {
  const readLocationsQuery = api.useReadLocationsQuery(undefined, {
    skip: auth.token === undefined,
  });
  const [markerLocationState, setMarkerLocationData] = useState<
    {
      samplingLocation: SamplingLocation;
      latitude: number;
      longitude: number;
      src: string;
    }[]
  >([]);
  const [markerDataState, setMarkerData] = useState<MarkerType[]>([]);

  useEffect(() => {
    /* console.log(
      "readLocationsQuery",
      readLocationsQuery,
      readLocationsQuery.data && readLocationsQuery.data.length > 0
    ); */
    if (readLocationsQuery.data && readLocationsQuery.data.length > 0) {
      const locationsWithData = mapFilter.filteredSamplingData.map(
        (it) => it.locationId
      );
      const locationsWithoutData = readLocationsQuery.data?.filter(
        (location) => {
          const filterCompliance =
            mapFilter.categories.includes(location.category) &&
            (mapFilter.section === "All sections" ||
              mapFilter.section.includes(location.section)) &&
            (mapFilter.sector === "All sectors" ||
              mapFilter.sector.includes(location.sector));
          if (locationsWithData.length === 0 && filterCompliance) {
            return true;
          }
          return (
            location.id &&
            !locationsWithData.includes(location.id) &&
            filterCompliance
          );
        }
      );
      const markerData = (locationsWithoutData || []).map((location) => {
        return {
          samplingLocation: location,
          latitude: location.defaultCoordinates.lat,
          longitude: location.defaultCoordinates.lon,
          src: `./marker-${imageMapping[location.category]}-gray.png`,
        };
      });
      setMarkerLocationData(markerData);
    } else {
      setMarkerLocationData([]);
    }
  }, [mapFilter, readLocationsQuery]);

  useEffect(() => {
    if (readLocationsQuery.data && readLocationsQuery.data.length > 0) {
      const markerData = mapFilter.filteredSamplingData.map((data) => {
        const currentLocation = readLocationsQuery.data?.find(
          (loc) => loc.id === data!.locationId
        );
        if (currentLocation === undefined) {
          console.error(
            "currentLocation is undefined, should be an object.",
            readLocationsQuery.data,
            mapFilter
          );
          throw Error("currentLocation is undefined, should be an object.");
        }
        return {
          samplingData: data!,
          samplingLocation: currentLocation,
          latitude: data!.coordinates.lat,
          longitude: data!.coordinates.lon,
          src: `./marker-${
            imageMapping[currentLocation.category]
          }-${getQualityColor(data!)}.png`,
        };
      });
      setMarkerData(markerData);
    } else {
      setMarkerData([]);
    }
  }, [mapFilter, readLocationsQuery]);

  return { markerLocationState, markerDataState };
};
