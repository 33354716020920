export default function getTrafficColor(quality: string) {
  switch (quality) {
    case "Good":
      return "green.light";
    case "Medium":
      return "yellow.light";
    case "Bad":
      return "primary.light";
    default:
      return "primary.light";
  }
}
