import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { Popup } from "react-map-gl";
import TrafficLight from "../../common/components/TrafficLight";
import { appViewSlice } from "../../data/appViewSlice";
import { useAppDispatch } from "../../data/store";
import { SamplingData } from "../../types/SamplingData";
import { SamplingLocation } from "../../types/SamplingLocation";

type MapboxPopupProps = {
  location: SamplingLocation;
  samplingData: SamplingData;
  setShowPopup: React.Dispatch<
    React.SetStateAction<{
      display: boolean;
      location: SamplingLocation | undefined;
      data: SamplingData | undefined;
    }>
  >;
};

export const MapboxPopup: React.FC<MapboxPopupProps> = (props) => {
  const dispatcher = useAppDispatch();
  return (
    <Popup
      latitude={props.samplingData.coordinates.lat}
      longitude={props.samplingData.coordinates.lon}
      anchor="bottom"
      closeButton={false}
      maxWidth="auto"
      style={{ zIndex: 2 }}
    >
      <Box margin="2rem" width="15rem">
        <Flex
          flexDirection="row"
          alignItems="center"
          marginBottom="1rem"
          justifyContent="space-between"
        >
          <Flex
            flexDirection="row"
            alignItems="center"
            gap="1rem"
            fontSize="1.5rem"
            fontFamily="serif"
          >
            <TrafficLight
              color={
                props.samplingData.quality === "Bad"
                  ? "primary.light"
                  : props.samplingData.quality === "Medium"
                  ? "yellow.light"
                  : "green.light"
              }
            />
            {props.location.name}
          </Flex>
          <CloseIcon
            boxSize="1.25rem"
            color="gray.500"
            _hover={{
              cursor: "pointer",
              color: "primary.main",
            }}
            onClick={() => {
              props.setShowPopup((state) => ({ ...state, display: false }));
            }}
          />
        </Flex>

        <Divider marginBottom="1rem" />
        <Box fontSize="0.875rem">
          Section{" "}
          <Text as={"span"} fontWeight="bold">
            {props.location.section}
          </Text>
        </Box>
        <Box fontSize="0.875rem">
          Km{" "}
          <Text as={"span"} fontWeight="bold">
            {props.location.km}
          </Text>
        </Box>
        <Box fontSize="0.875rem">
          Sampled Date:{" "}
          <Text as={"span"} fontWeight="bold">
            {DateTime.fromSeconds(props.samplingData.inspectionDate).toFormat(
              "dd LLL yyyy"
            )}
          </Text>
        </Box>
        <Box fontSize="0.875rem">
          Quality:{" "}
          <Text as={"span"} fontWeight="bold">
            {props.samplingData.quality}
          </Text>
        </Box>

        <Divider marginTop="1rem" marginBottom="0.5rem" />
        <Button
          minWidth="100%"
          variant="solidSecondary"
          marginTop="1rem"
          onClick={() => {
            props.setShowPopup((state) => ({ ...state, display: false }));
            dispatcher(appViewSlice.actions.set({ value: "detail" }));
          }}
        >
          {"See more"} <ArrowForwardIcon marginLeft="1rem" />
        </Button>
      </Box>
    </Popup>
  );
};
