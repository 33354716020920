import { Box } from "@chakra-ui/react";
import { Chart, ChartData, registerables } from "chart.js";
import "chartjs-adapter-luxon";
import { DateTime } from "luxon";
import React from "react";
import { Line } from "react-chartjs-2";
import { MAX_DATE, MIN_DATE_2019 } from "../../../../config/constants";

import theme from "../../../../config/theme";
import { detailViewSlice } from "../../../../data/detailViewSlice";
import { mapFilterSlice } from "../../../../data/mapFilterSlice";
import { useAppDispatch, useAppSelector } from "../../../../data/store";

export type ChartDataXY = { x: DateTime; y: number };

Chart.register(...registerables);

interface IProps {
  data: { green: ChartDataXY[]; red: ChartDataXY[]; yellow: ChartDataXY[] };
  parameterNameOrId: string | number;
}

const LineChart: React.FC<IProps> = (props) => {
  const dispatcher = useAppDispatch();
  const mapFilter = useAppSelector((state) => state.mapFilter.value);
  const detailView = useAppSelector((state) => state.detailView);

  const dataFlat = Object.values(props.data).flatMap((it) => it);
  const dataSorted = dataFlat
    .map((d) => d.y)
    .sort((a, b) => {
      if (!isFinite(a) && !isFinite(b)) {
        return 0;
      }
      if (!isFinite(a)) {
        return -1;
      }
      if (!isFinite(b)) {
        return -1;
      }
      return a - b;
    });

  const range: number =
    dataSorted.length === 1
      ? 4
      : dataSorted[dataSorted.length - 1] - dataSorted[0];
  const options = {
    type: "line",
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
    },
    scales: {
      x: {
        ticks: { display: true },
        type: "time",
        time: {
          unit: "month",
          displayFormats: {
            quarter: "MMM YYYY",
          },
        },
        min: MIN_DATE_2019.toUnixInteger() * 1000, // to milliseconds
        max: MAX_DATE.toUnixInteger() * 1000,
      },
      y: {
        ticks: {
          stepSize: range / 4,
          callback: function (value: number, index: number, ticks: any) {
            if (props.parameterNameOrId === "Overall performance") {
              if (value === 1) {
                return "Bad";
              } else if (value === 2) {
                return "Medium";
              } else if (value === 3) {
                return "Good";
              } else {
                return;
              }
            } else {
              return value.toFixed(2);
            }
          },
        },
      },
    },
    onClick: (event: any, array: Record<string, any>[]) => {
      if (array.length > 0) {
        const dateRaw: DateTime = array[0]["element"]["$context"]["raw"].x;
        if (detailView.samplingData) {
          dispatcher(
            detailViewSlice.actions.setSamplingData({
              data: detailView.samplingData,
              dateUnixTimestamp: dateRaw.toUnixInteger(),
            })
          );
        }
        dispatcher(
          mapFilterSlice.actions.set({
            ...mapFilter,
            inspectionDate: dateRaw.toUnixInteger(),
          })
        );
      }
    },
  };
  const data: ChartData<any, ChartDataXY[] | number[], string> = {
    datasets: [
      {
        label: "Line",
        data: Object.values(props.data)
          .flatMap((it) => it)

          // .sort((a, b) =>
          //   a.y === NaN || b.y === NaN
          //     ? 0
          //     : a.x.toUnixInteger() - b.x.toUnixInteger()
          // ),
          .sort((a, b) => a.x.toUnixInteger() - b.x.toUnixInteger()),
        borderColor: theme.colors.black[100],
        backgroundColor: theme.colors.black[100],
        borderWidth: 1,
        type: "line",
        order: 10,
        tension: 0,
      },
      {
        label: "Good",
        backgroundColor: theme.colors.green.light,
        borderColor: theme.colors.light,
        data: props.data.green,
        borderWidth: 0,
        pointRadius: 7,
        pointHoverRadius: 9,
      },
      {
        label: "Medium",
        backgroundColor: theme.colors.yellow.light,
        borderColor: theme.colors.yellow.light,
        data: props.data.yellow,
        borderWidth: 0,
        pointRadius: 7,
        pointHoverRadius: 9,
      },
      {
        label: "Bad",
        backgroundColor: theme.colors.primary.light,
        borderColor: theme.colors.primary.light,
        data: props.data.red,
        borderWidth: 0,
        pointRadius: 7,
        pointHoverRadius: 9,
      },
      {
        label: "Current",
        backgroundColor: "#ffffff00",
        borderColor: theme.colors.black[100],
        data: [
          dataFlat.find(
            (it) =>
              it.x.month ===
                DateTime.fromSeconds(mapFilter.inspectionDate).month &&
              it.x.year ===
                DateTime.fromSeconds(mapFilter.inspectionDate).year &&
              it.x.day === DateTime.fromSeconds(mapFilter.inspectionDate).day
          ),
        ],
        borderWidth: 1,
        pointRadius: 12,
        pointHoverRadius: 13,
        order: 10,
      },
    ],
  };
  return (
    <>
      <style>{`@media print {canvas {-webkit-print-color-adjust: exact !important; color-adjust: exact !important;}}`}</style>
      {/* <Box className="print-line-chart" width="100%" height="100%">
        <Line
          width={450}
          height={350}
          data={data}
          options={
            { ...options, responsive: false, maintainAspectRatio: false } as any
          }
        />
      </Box> */}
      <Box className="line-chart" height="100%" position="relative">
        <Line data={data as any} options={options as any} />
      </Box>
    </>
  );
};

export default LineChart;
