import { Box } from "@chakra-ui/react";
import React from "react";

type TitleProps = { text: string };

export const Title: React.FC<TitleProps> = (props) => {
  return (
    <Box fontSize="heading3" fontFamily="heading" color="black">
      {props.text}
    </Box>
  );
};
