import React from "react";
import { Text } from "@chakra-ui/react";

interface IProps {
  color?: string;
}

const SpanSeparator: React.FC<IProps> = (props) => {
  const { color = "gray.500" } = props;
  return (
    <Text as="span" fontSize="small" color={color}>
      {" "}
      /{" "}
    </Text>
  );
};
export default SpanSeparator;
