import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { api } from "../../../data/api";
import { newSamplingDataViewSlice } from "../../../data/newSamplingDataViewSlice";
import { useAppDispatch, useAppSelector } from "../../../data/store";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenNewSamplingLocation: () => void;
  onOpenNewSamplingData: () => void;
}

const AddForm: React.FC<IProps> = (props) => {
  const categories = useAppSelector((state) => state.config.value.categories);
  const auth = useAppSelector((state) => state.auth);
  const locationResponse = api.useReadLocationsQuery(undefined, {
    skip: auth.token === undefined,
  });
  const [type, setType] = useState<"existing" | "new">("existing");
  const [formCategory, setFormCategory] = useState<string>();
  const dispatcher = useAppDispatch();

  useEffect(() => {
    if (categories.length > 0) {
      setFormCategory(categories[0]);
    }
  }, [categories]);

  useEffect(() => {
    dispatcher(
      newSamplingDataViewSlice.actions.setLocation(
        locationResponse.data?.filter((it) => it.category === formCategory)[0]
      )
    );
  }, [formCategory, dispatcher, locationResponse]);

  return (
    <Modal isCentered isOpen={props.isOpen} onClose={props.onClose} size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" px="2rem" pb="0.5rem">
          <Text fontWeight="bold" color="primary.main" fontSize="base">
            Add sampling data
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="2rem" pb="2rem">
          <form method="POST">
            <Divider mb={3} />
            <VStack w="100%" gap={3} fontSize="small">
              <Box w="100%">
                <Text mb={2}>Type</Text>
                <Select
                  onClick={(ev) =>
                    // @ts-ignore
                    setType(ev.currentTarget.value)
                  }
                >
                  <option value="existing">Existing sampling location</option>
                  <option value="new">New sampling location</option>
                </Select>
              </Box>
              <Box w="100%">
                <Text mb={2} opacity={type === "new" ? 0.5 : 1}>
                  Category
                </Text>
                <Select
                  defaultValue={formCategory}
                  disabled={type === "new"}
                  onClick={(ev) => {
                    setFormCategory(ev.currentTarget.value);
                  }}
                >
                  {categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box w="100%">
                <Text mb={2} opacity={type === "new" ? 0.5 : 1}>
                  Sampling location
                </Text>
                <Select
                  key={new Date().valueOf()} // This is necessary to make react not to remember the last selection.
                  disabled={type === "new"}
                  onChange={(ev) => {
                    const newValue = ev.currentTarget.value;
                    dispatcher(
                      newSamplingDataViewSlice.actions.setLocation(
                        locationResponse.data?.find((it) => it.id === +newValue)
                      )
                    );
                  }}
                >
                  {locationResponse.data
                    ?.filter((it) => it.category === formCategory)
                    .map((location, index) => (
                      <option value={location.id} key={index}>
                        {location.name}
                      </option>
                    ))}
                </Select>
              </Box>
              <Divider />
              <Button
                disabled={
                  type !== "new" &&
                  locationResponse.data?.filter(
                    (it) => it.category === formCategory
                  ).length === 0
                }
                w="100%"
                variant="solidPrimary"
                rightIcon={<ArrowForwardIcon />}
                onClick={() => {
                  if (type === "new") {
                    props.onClose();
                    props.onOpenNewSamplingLocation();
                  } else if (type === "existing") {
                    props.onClose();
                    props.onOpenNewSamplingData();
                  }
                }}
              >
                {type === "existing" ? "Fill data" : "Create location"}
              </Button>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default AddForm;
