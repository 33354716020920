import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

import Button from "./theme/Button";
import Checkbox from "./theme/Checkbox";
import Link from "./theme/Link";
import Input from "./theme/Input";
import Select from "./theme/Select";
import Popover from "./theme/Popover";
import { table } from "./theme/Table";

const breakpoints = createBreakpoints({
  sm: "520px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
});

const theme = extendTheme({
  colors: {
    primary: {
      main: "#E61E28",
      light: "#E61E28CC",
    },
    yellow: {
      dark: "#E9BF14",
      light: "#FBC700",
    },
    green: {
      dark: "#528B30",
      light: "#54AE4F",
      transparent: "#ddecd6",
    },
    blue: {
      dark: "#2F75B5",
    },
    black: {
      100: "#182322",
      200: "#2E3234",
      300: "#525758",
      400: "#707070",
      500: "#6B7675",
      600: "#95979A",
      800: "#B5B5B8",
      900: "#E9E9EB",
    },
    white: {
      100: "#0000001A",
      200: "#F5F6F8",
      300: "#F5F5F5",
      400: "#F8F8F9",
      500: "#FFFFFF",
    },
    gray: {
      100: "#9FA1A4",
      200: "#E2E8F0",
      500: "#718096",
      600: "#4A5568",
      tr: "#F9FBFD",
    },
  },
  fonts: {
    heading: "Times",
    body: "Arial",
  },
  fontSizes: {
    heading1: "2rem",
    heading2: "1.375rem",
    heading3: "1.125rem",
    base: "1rem",
    small: "0.8125rem",
    xs: "0.625rem",
  },
  components: {
    Button,
    Checkbox,
    Link,
    Input,
    Select,
    Popover,
    Table: table,
  },
  styles: {
    global: () => ({
      html: {
        height: "100%",
        fontSize: "12px",
        "@media screen and (max-width: 1300)": {
          fontSize: "12px",
        },
        "@media screen and (min-width: 1300) and (max-width: 1560px)": {
          fontSize: "14px",
        },
        "@media screen and (min-width: 1560px)": {
          fontSize: "16px",
        },
        "@media screen and (min-width: 2500px)": {
          fontSize: "18px",
        },
      },
      body: {
        height: "100%",
        fontFamily: "body",
        lineHeight: "base",
        bg: "white.400",
        "#root": {
          height: "100%",
          whiteSpace: "pre-line",
        },
      },
      "::-webkit-scrollbar": {
        width: "8px",
      },
      "::-webkit-scrollbar-track": {
        background: "white.200",
      },
      "::-webkit-scrollbar-thumb": {
        background: "gray.200",
        borderRadius: "6px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "gray.100",
      },
    }),
  },
  breakpoints,
});

export default theme;
