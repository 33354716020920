import { SamplingData } from "../types/SamplingData";
import { QualityColor } from "./MapboxComponent";

export function getQualityColor(data: SamplingData): QualityColor {
  return data.quality === "Bad"
    ? "red"
    : data.quality === "Medium"
      ? "yellow"
      : "green";
}
