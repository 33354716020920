import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridItem,
  Select,
  useMediaQuery,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { ProgressBar } from "../common/ProgressBar";
import { SmallText } from "../common/text/SmallText";
import { SubtitleRed } from "../common/text/SubtitleRed";
import { Title } from "../common/text/Title";
import { DATES } from "../config/constants";
import { appViewSlice } from "../data/appViewSlice";
import { mapFilterSlice } from "../data/mapFilterSlice";
import { useAppDispatch, useAppSelector } from "../data/store";

const colors = ["#F2454D", "#FFD10A", "#68C163"];

interface IProps {}

const imageMapping: Record<string, string> = {
  Water: "./marker-circle-black.png",
  Air: "./marker-triangle-black.png",
  Soil: "./marker-square-black.png",
  Noise: "./marker-pentagon-black.png",
  Vibrations: "./marker-star-black.png",
  Groundwater: "./marker-hexagon-black.png",
};

export const Filter: React.FC<IProps> = () => {
  const auth = useAppSelector((state) => state.auth);
  const config = useAppSelector((state) => state.config.value);
  const mapFilter = useAppSelector((state) => state.mapFilter.value);

  const dispatcher = useAppDispatch();

  useEffect(() => {
    dispatcher(
      mapFilterSlice.actions.set({
        ...mapFilter,
        categories: config.categories,
        qualities: config.qualities,
        section: "All sections",
        sector: "All sectors",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, dispatcher]);

  // javier.sepulveda: this quick fix is done to filter sections when a sector is selected.
  let curatedSections: string[] = config.sections;
  if (mapFilter.sector === "Sector 1") {
    curatedSections = config.sections.slice(0, 3);
  } else if (mapFilter.sector === "Sector 2") {
    curatedSections = config.sections.slice(3, 7);
  } else if (mapFilter.sector === "Sector 3") {
    curatedSections = config.sections.slice(7, 10);
  }

  const [isMoreThan1281] = useMediaQuery("(min-width: 1281px)");

  return (
    <Box
      className="Filter"
      padding={!isMoreThan1281 ? "1rem 2rem" : "2rem"}
      borderRadius="0.4rem"
      height="fit-content"
      maxHeight="100%"
      backgroundColor="white"
      overflowY="auto"
      overflowX="hidden"
    >
      <Flex justify="space-between">
        <Box>
          <SubtitleRed text="Morava Road" />
          <Title text="Mitigation" />
        </Box>
      </Flex>
      <Divider marginTop={!isMoreThan1281 ? "0.5rem" : "1rem"} />
      <Grid
        gap="0rem"
        templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(1, 1fr)" }}
        columnGap="3rem"
      >
        <GridItem>
          <SmallText text="Sector" />
          <Select
            marginBottom="0rem"
            onChange={(event) =>
              dispatcher(
                mapFilterSlice.actions.set({
                  sector: event.currentTarget.value,
                  section: mapFilter.section,
                  inspectionDate: mapFilter.inspectionDate,
                  categories: mapFilter.categories,
                  qualities: mapFilter.qualities,
                  filteredSamplingData: mapFilter.filteredSamplingData,
                  refetch: mapFilter.refetch,
                })
              )
            }
          >
            <option value="All sectors">All sectors</option>
            {config.sectors.map((sector, index) => (
              <option key={index} value={sector}>
                {sector}
              </option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <SmallText text="Section" />
          <Select
            marginBottom="0rem"
            onChange={(event) =>
              dispatcher(
                mapFilterSlice.actions.set({
                  sector: mapFilter.sector,
                  section: event.currentTarget.value,
                  inspectionDate: mapFilter.inspectionDate,
                  categories: mapFilter.categories,
                  qualities: mapFilter.qualities,
                  filteredSamplingData: mapFilter.filteredSamplingData,
                  refetch: mapFilter.refetch,
                })
              )
            }
          >
            <option value="All sections">All sections</option>
            {curatedSections.map((section, index) => (
              <option key={index} value={section}>
                {section}
              </option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <SmallText text="Sampled date" />
          <Select
            onChange={(event) =>
              dispatcher(
                mapFilterSlice.actions.set({
                  sector: mapFilter.sector,
                  section: mapFilter.section,
                  inspectionDate: +event.currentTarget.value,
                  categories: mapFilter.categories,
                  qualities: mapFilter.qualities,
                  filteredSamplingData: mapFilter.filteredSamplingData,
                  refetch: mapFilter.refetch,
                })
              )
            }
            value={
              DATES.find(
                (it) =>
                  it.month ===
                    DateTime.fromSeconds(mapFilter.inspectionDate).month &&
                  it.year ===
                    DateTime.fromSeconds(mapFilter.inspectionDate).year
              )?.toUnixInteger() || DATES[0].toUnixInteger()
            }
          >
            {DATES.map((date, index) => (
              <option key={index} value={date.toUnixInteger()}>
                {date.toFormat("yyyy LLLL")}
              </option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <SmallText text="Category" />
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap="0.5rem 0rem"
            marginBottom={!isMoreThan1281 ? "0.5rem" : "1rem"}
          >
            {config.categories.map((category, index) => (
              <GridItem key={index} w="100%" height="1.1rem">
                <Checkbox
                  width="100%"
                  defaultChecked
                  value={category}
                  onChange={(event) => {
                    let categories = [];
                    if (event.currentTarget.checked) {
                      categories = [...mapFilter.categories];
                      categories.push(category);
                      categories = Array.from(new Set(categories));
                    } else {
                      categories = mapFilter.categories.filter(
                        (it) => it !== category
                      );
                    }
                    dispatcher(
                      mapFilterSlice.actions.set({
                        sector: mapFilter.sector,
                        section: mapFilter.section,
                        inspectionDate: mapFilter.inspectionDate,
                        categories: categories,
                        qualities: mapFilter.qualities,
                        filteredSamplingData: mapFilter.filteredSamplingData,
                        refetch: mapFilter.refetch,
                      })
                    );
                  }}
                >
                  <Flex flexDirection="row" alignItems="center">
                    <img
                      src={imageMapping[category]}
                      style={{
                        display: "inline",
                        marginRight: "0.3rem",
                        color: "black",
                      }}
                      width="10px"
                      alt="icon"
                    />
                    <Box
                      flexGrow={1}
                      className="filter-text"
                      as="span"
                      height="1rem"
                      lineHeight="1rem"
                      width="calc(100% - 1rem)"
                      display="inline-block"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {category}
                    </Box>
                  </Flex>
                </Checkbox>
              </GridItem>
            ))}
          </Grid>
          <Divider display={{ sm: "none", md: "block" }} />
        </GridItem>
        <GridItem>
          <SmallText text="Sampling quality" />
          <Grid
            templateColumns="repeat(1, 1fr)"
            gap="0.5rem 0rem"
            marginBottom={!isMoreThan1281 ? "0.5rem" : "1rem"}
            width="100%"
          >
            {config.qualities.map((quality, index) => (
              <GridItem key={index} w="100%" h="5">
                <Checkbox
                  defaultChecked
                  width="100%"
                  onChange={(event) => {
                    let qualities = [];
                    if (event.currentTarget.checked) {
                      qualities = [...mapFilter.qualities];
                      qualities.push(quality);
                      qualities = Array.from(new Set(qualities));
                    } else {
                      qualities = mapFilter.qualities.filter(
                        (it) => it !== quality
                      );
                    }
                    dispatcher(
                      mapFilterSlice.actions.set({
                        sector: mapFilter.sector,
                        section: mapFilter.section,
                        inspectionDate: mapFilter.inspectionDate,
                        categories: mapFilter.categories,
                        qualities: qualities,
                        filteredSamplingData: mapFilter.filteredSamplingData,
                        refetch: mapFilter.refetch,
                      })
                    );
                  }}
                >
                  <Flex flexDirection="row">
                    <Box
                      minWidth="3rem"
                      paddingRight="0.5rem"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {quality}
                    </Box>
                    <Box width="6rem" paddingTop="2px">
                      {mapFilter.filteredSamplingData.filter(
                        (it) => it.quality === quality
                      ).length > 0 && (
                        <ProgressBar
                          current={
                            mapFilter.filteredSamplingData.filter(
                              (it) => it.quality === quality
                            ).length
                          }
                          total={mapFilter.filteredSamplingData.length}
                          color={colors[index]}
                        ></ProgressBar>
                      )}
                    </Box>
                  </Flex>
                </Checkbox>
              </GridItem>
            ))}
          </Grid>
        </GridItem>
        <GridItem>
          {auth.groups &&
            auth.groups.length > 0 &&
            auth.groups.find(
              (group) =>
                group.split(":").includes("create") ||
                group.split(":").includes("write")
            ) && (
              <Button
                minWidth={{ sm: "50%", md: "100%" }}
                variant="solidSecondary"
                marginTop="0.5rem"
                onClick={() =>
                  dispatcher(
                    appViewSlice.actions.set({
                      value: "samplingDataTable",
                    })
                  )
                }
              >
                Managing samples
              </Button>
            )}
        </GridItem>
      </Grid>
    </Box>
  );
};
