import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { LocationBreadcrumb } from "../../../../common/components/LocationBreadcrumb";
import TrafficLight from "../../../../common/components/TrafficLight";
import { api } from "../../../../data/api";
import { SamplingData } from "../../../../types/SamplingData";
import { SamplingLocation } from "../../../../types/SamplingLocation";

interface IProps {
  location: SamplingLocation;
  data: SamplingData;
  isOpen: boolean;
  onClose: () => void;
}

const DetailsModal: React.FC<IProps> = (props) => {
  const { location, isOpen, onClose } = props;
  const getSignedUrl = api.useGetSignedUrlQuery(props.data.images, {
    skip: props.data.images.length === 0,
  });
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" px="2rem">
          <LocationBreadcrumb location={location} />
          <Flex alignItems="center" gap={2}>
            <TrafficLight
              color={
                props.data.quality === "Bad"
                  ? "primary.main"
                  : props.data.quality === "Medium"
                  ? "yellow.light"
                  : "green.light"
              }
            />
            <Text fontFamily="Times" fontSize="heading3">
              {location.name}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="2rem" pb="2rem">
          <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} fontSize="small">
            <Box>
              <Text mb={2}>Extra comment</Text>
              <Text color="gray.600">{props.data.comment}</Text>
            </Box>
            <Box>
              <Text mb={2}>Images of sampling conditions</Text>
              <Box width="100%">
                {getSignedUrl.data &&
                  getSignedUrl.data.map((i) => (
                    <Image
                      key={i}
                      src={i}
                      alt="Sampling condition"
                      maxHeight="15rem"
                      marginBottom="1rem"
                    />
                  ))}
              </Box>
            </Box>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default DetailsModal;
