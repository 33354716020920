import React from "react";
import { Flex, IconButton, Image, Text, Tooltip } from "@chakra-ui/react";
import { MdExitToApp } from "react-icons/md";

import arupRedLogo from "../assets/svg/arup_red.svg";
import { envConfig } from "../config/envConfig";
import { logout } from "./auth/logout";

export const Navbar: React.FC = () => {
  return (
    <Flex
      className="dont-print"
      flexDir="row"
      width="100%"
      justify="space-between"
      height="3.5rem"
      padding="0 3rem"
      backgroundColor="white.500"
    >
      <Flex alignItems="center" height="100%">
      <Text fontSize="heading3" fontFamily="heading" color="primary.main">
          d.Hub
        </Text>
        <Text fontSize="heading3" fontFamily="heading" marginLeft={1}>
          {envConfig.APP_NAME}
        </Text>
      </Flex>
      <Flex alignItems={"center"}>
        <Tooltip label="Logout">
          <IconButton
            fontSize="1rem"
            aria-label="Logout"
            icon={<MdExitToApp />}
            variant="noBackground"
            color="primary.main"
            onClick={() => logout()}
            height="100%"
            _hover={{ cursor: "pointer" }}
          />
        </Tooltip>

        <Image src={arupRedLogo} alt="Arup Logo" height="1.125rem" />
      </Flex>
    </Flex>
  );
};
