import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Config } from "../types/Config";

interface ConfigState {
  value: Config;
}

// Define the initial state using that type
const initialState: ConfigState = {
  value: {
    categories: [],
    classes: {},
    qualities: [],
    sectors: [],
    sections: [],
    parameterSchemaIds: {},
    defaultCoordinates: { lat: 43.856729, lon: 20.805732 },
  },
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Config>) => {
      state.value = action.payload;
    },
  },
});
