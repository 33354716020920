import { Box, Divider, Skeleton, Text } from "@chakra-ui/react";
import { Popup } from "react-map-gl";
import { api } from "../../data/api";
import { useAppDispatch, useAppSelector } from "../../data/store";
import { SamplingData } from "../../types/SamplingData";
import { DataItem } from "./DataItem";

type MapboxPopupProps = {
  locationId: number;
  name: string;
  lat: number;
  lon: number;
  setShow: React.Dispatch<
    React.SetStateAction<{
      display: boolean;
      locationId?: number | undefined;
      name?: string | undefined;
      lat?: number | undefined;
      lon?: number | undefined;
    }>
  >;
};

export const DatesForLocation: React.FC<MapboxPopupProps> = (props) => {
  const dispatcher = useAppDispatch();
  const mapFilter = useAppSelector((state) => state.mapFilter.value);
  const auth = useAppSelector((state) => state.auth);

  const readLocationsQuery = api.useReadSamplingDataByLocationQuery(
    props.locationId,
    {
      skip: auth.token === undefined,
    }
  );

  const data: SamplingData[] = [...(readLocationsQuery.data || [])].sort(
    (a, b) => a.inspectionDate - b.inspectionDate
  );

  return (
    <Popup
      latitude={props.lat}
      longitude={props.lon}
      anchor="bottom"
      closeButton={false}
      maxWidth="auto"
      style={{ zIndex: 2 }}
    >
      <Box margin="2rem" width="15rem">
        <Text fontSize="1.5rem" fontFamily="serif">
          {props.name}
        </Text>
        <Divider marginTop="1rem" marginBottom="1rem" />
        {readLocationsQuery.isSuccess && data.length === 0 && (
          <Text>No data.</Text>
        )}
        {readLocationsQuery.isSuccess && data.length > 0 && (
          <Box width="100%" maxHeight="18rem" overflowY="auto">
            <Text marginBottom="0.5rem">Change sampled date:</Text>
            {data.map((it) => (
              <DataItem
                key={it.id}
                data={it}
                dispatcher={dispatcher}
                mapFilter={mapFilter}
                setShow={props.setShow}
              />
            ))}
          </Box>
        )}
        {readLocationsQuery.isError && (
          <Text color="red.500">Error loading data</Text>
        )}
        {readLocationsQuery.isLoading && (
          <>
            <Skeleton width="45%" height="1rem" marginBottom="0.5rem" />
            <Skeleton width="100%" height="2rem" />
          </>
        )}
      </Box>
    </Popup>
  );
};
