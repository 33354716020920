import { ComponentStyleConfig } from "@chakra-ui/react";

export const table: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: "body",
    th: {
      paddingStart: 0,
      textTransform: "none",
      paddingLeft: 0,
      fontFamily: "body",
      letterSpacing: "normal",
      borderBottomWidth: "1rem",
      fontSize: "0.75rem",
    },
    td: {
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
      paddingStart: 0,
      paddingLeft: 0,
      borderBottomWidth: "1rem",
      fontSize: "0.875rem",
    },
    borderColor: "black.500",
  },
};
