import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { api } from "../../../data/api";
import { newSamplingDataViewSlice } from "../../../data/newSamplingDataViewSlice";
import { useAppDispatch, useAppSelector } from "../../../data/store";
import { SamplingLocation } from "../../../types/SamplingLocation";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenNext: () => void;
}

const NewSamplingLocationForm: React.FC<IProps> = (props) => {
  const errorToast = useToast();
  const [isValid, setValidation] = useState(false);
  const [isValidExpression, setValidExpression] = useState(false);
  const [form, setForm] = useState<SamplingLocation>({
    name: "",
    category: "",
    defaultCoordinates: { lat: 0, lon: 0 },
    id: undefined,
    km: "",
    section: "",
    sector: "",
  });
  const [updateLocation, updateState] = api.useUpdateLocationMutation();
  const { isOpen, onClose } = props;
  const auth = useAppSelector((state) => state.auth);
  const readLocationsQuery = api.useReadLocationsQuery(undefined, {
    skip: auth.token === undefined,
  });
  const sectors = useAppSelector((state) => state.config.value.sectors);
  const sections = useAppSelector((state) => state.config.value.sections);
  const categories = useAppSelector((state) => state.config.value.categories);
  const dispatcher = useAppDispatch();

  // At start the Sector, Section and Category of the form is set to the first available.
  useEffect(() => {
    setForm((state) => ({
      ...state,
      sector: sectors[0],
      section: sections[0],
      category: categories[0],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateState.isSuccess) {
      props.onClose();
      props.onOpenNext();
    } else if (updateState.isError) {
      errorToast({
        title: "Error.",
        description: "There was a problem with the server. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      props.onClose();
    }
  }, [updateState, props, errorToast]);

  // Validation logic
  useEffect(() => {
    setValidation(form.name !== "" && form.km !== "" && isValidExpression);
  }, [form, isValidExpression]);

  console.log(isValid);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" px="2rem" pb="0.5rem">
          <Text fontWeight="bold" color="primary.main" fontSize="base">
            New sampling location
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="2rem" pb="2rem">
          <form method="POST">
            <Divider mb={3} />
            <SimpleGrid columns={2} w="100%" gap={3} fontSize="small">
              <Box w="100%">
                <Text mb={2}>
                  Name
                  <Text as="span" fontSize="small" color="primary.main">
                    *{" "}
                  </Text>
                </Text>
                <Input
                  required
                  borderColor={form.name !== "" ? undefined : "primary.light"}
                  onChange={(ev) => {
                    const newValue = ev.currentTarget.value;
                    setForm((state) => ({
                      ...state,
                      name: newValue,
                    }));
                  }}
                  placeholder="Name"
                  h="2rem"
                />
              </Box>
              <Box w="100%">
                <Text mb={2}>
                  Section
                  <Text as="span" fontSize="small" color="primary.main">
                    *{" "}
                  </Text>
                </Text>
                <Select
                  required
                  onChange={(ev) => {
                    const newValue = ev.currentTarget.value;
                    setForm((state) => ({
                      ...state,
                      section: newValue,
                    }));
                  }}
                >
                  {sections.map((section, index) => (
                    <option key={index} value={section}>
                      {section}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box w="100%">
                <Text mb={2}>
                  Coordinates (latitude, longitude)
                  <Text as="span" fontSize="small" color="primary.main">
                    *{" "}
                  </Text>
                </Text>
                <Input
                  h="2rem"
                  required
                  isInvalid={!isValidExpression}
                  placeholder="00.0000, 00.0000"
                  borderColor={isValidExpression ? undefined : "primary.light"}
                  onChange={(ev) => {
                    const validExpression = ev.currentTarget.value.match(
                      "^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$"
                    );
                    if (validExpression) {
                      setValidExpression(true);
                    } else {
                      setValidExpression(false);
                    }
                    const newValue = ev.currentTarget.value;
                    setForm((state) => ({
                      ...state,
                      defaultCoordinates: {
                        lat: +newValue.split(",")[0],
                        lon: +newValue.split(",")[1],
                      },
                    }));
                  }}
                />
              </Box>
              <Box w="100%">
                <Text mb={2}>
                  Sector
                  <Text as="span" fontSize="small" color="primary.main">
                    *{" "}
                  </Text>
                </Text>
                <Select
                  required
                  onChange={(ev) => {
                    const newValue = ev.currentTarget.value;
                    setForm((state) => ({
                      ...state,
                      sector: newValue,
                    }));
                  }}
                >
                  {sectors.map((sector, index) => (
                    <option key={index} value={sector}>
                      {sector}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box w="100%">
                <Text mb={2}>
                  Category
                  <Text as="span" fontSize="small" color="primary.main">
                    *{" "}
                  </Text>
                </Text>
                <Select
                  required
                  onChange={(ev) => {
                    const newValue = ev.currentTarget.value;
                    setForm((state) => ({
                      ...state,
                      category: newValue,
                    }));
                  }}
                >
                  {categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box w="100%">
                <Text mb={2}>
                  Km
                  <Text as="span" fontSize="small" color="primary.main">
                    *{" "}
                  </Text>
                </Text>
                <Input
                  required
                  borderColor={form.km !== "" ? undefined : "primary.light"}
                  onChange={(ev) => {
                    const newValue = ev.currentTarget.value;
                    setForm((state) => ({
                      ...state,
                      km: newValue,
                    }));
                  }}
                  placeholder="64+000"
                  h="2rem"
                />
              </Box>
            </SimpleGrid>
            <Divider my={6} />
            <Flex w="100%" justify="space-between">
              <Box as="i">
                <Text as="span" fontSize="small" color="primary.main">
                  *{" "}
                </Text>
                <Text as="span" fontSize="small" color="gray.600">
                  Required fields
                </Text>
              </Box>
              <Button
                disabled={!isValid}
                alignSelf="right"
                w="50%"
                variant="solidPrimary"
                rightIcon={<ArrowForwardIcon />}
                onClick={async () => {
                  const locationResponse = await updateLocation(form);
                  if (Object.keys(locationResponse).includes("data")) {
                    const location = (
                      locationResponse as {
                        data: SamplingLocation;
                      }
                    ).data;
                    dispatcher(
                      newSamplingDataViewSlice.actions.setLocation(location)
                    );
                    readLocationsQuery.refetch();
                  } else {
                    // alert("Something went wrong when updating a location.");
                    // window.location.reload();
                  }
                }}
              >
                Save and fill data
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default NewSamplingLocationForm;
