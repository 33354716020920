import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SamplingData } from "../types/SamplingData";
import { SamplingLocation } from "../types/SamplingLocation";

interface NewSamplingDataViewState {
  selectedData?: SamplingData;
  selectedLocation?: SamplingLocation;
}

// Define the initial state using that type
const initialState: NewSamplingDataViewState = {
  selectedData: undefined,
  selectedLocation: undefined,
};

export const newSamplingDataViewSlice = createSlice({
  name: "newSamplingDataSlice",
  initialState,
  reducers: {
    setLocation: (
      state,
      action: PayloadAction<SamplingLocation | undefined>
    ) => {
      state.selectedLocation = action.payload;
    },
    setData: (state, action: PayloadAction<SamplingData | undefined>) => {
      state.selectedData = action.payload;
    },
    reset: (state) => {
      state.selectedData = undefined;
      state.selectedLocation = undefined;
    },
  },
});
