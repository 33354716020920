import React from "react";
import { Box } from "@chakra-ui/react";

interface ContentProps {
  children: React.ReactNode;
}

export const Content: React.FC<ContentProps> = (props) => {
  return (
    <Box
      className="content"
      height="calc(100% - 3rem)"
      width="100%"
      backgroundColor="white"
    >
      {props.children}
    </Box>
  );
};
