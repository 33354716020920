import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { Parameter } from "../types/Parameter";
import { SamplingData } from "../types/SamplingData";
import { SamplingLocation } from "../types/SamplingLocation";

export interface DetailViewState {
  samplingData?: SamplingData[];
  parameterListChart: Parameter[];
  parameterListTable: Parameter[];
  selectedLocation?: SamplingLocation;
  parameterNameOrId: string;
  currentSamplingData?: SamplingData;
}

// Define the initial state using that type
const initialState: DetailViewState = {
  parameterNameOrId: "Overall performance",
  parameterListChart: [],
  parameterListTable: [],
};

const getCurrentSamplingData = (
  data: SamplingData[],
  dateUnixTimestamp: number
): SamplingData | undefined => {
  return data.find(
    (d) =>
      DateTime.fromSeconds(d.inspectionDate).year ===
        DateTime.fromSeconds(dateUnixTimestamp).year &&
      DateTime.fromSeconds(d.inspectionDate).month ===
        DateTime.fromSeconds(dateUnixTimestamp).month
  );
};

export const detailViewSlice = createSlice({
  name: "detailView",
  initialState,
  reducers: {
    setParameterId: (
      state,
      action: PayloadAction<{
        parameterId: string;
        dateUnixTimestamp: number;
      }>
    ) => {
      state.parameterNameOrId = action.payload.parameterId;
      if (state.samplingData)
        state.currentSamplingData = getCurrentSamplingData(
          state.samplingData,
          action.payload.dateUnixTimestamp
        );
    },
    setParameterListChart: (state, action: PayloadAction<Parameter[]>) => {
      state.parameterListChart = action.payload;
    },
    setParameterListTable: (state, action: PayloadAction<Parameter[]>) => {
      state.parameterListTable = action.payload;
    },
    setSelectedLocation: (state, action: PayloadAction<SamplingLocation>) => {
      state.selectedLocation = action.payload;
    },
    setSamplingData: (
      state,
      action: PayloadAction<{ data: SamplingData[]; dateUnixTimestamp: number }>
    ) => {
      state.samplingData = action.payload.data;
      state.currentSamplingData = getCurrentSamplingData(
        action.payload.data,
        action.payload.dateUnixTimestamp
      );
    },
    reset: (state) => {
      state.samplingData = undefined;
      state.parameterListChart = [];
      state.selectedLocation = undefined;
      state.parameterNameOrId = initialState.parameterNameOrId;
      state.currentSamplingData = undefined;
    },
  },
});
