import React from "react";
import { Text } from "@chakra-ui/react";
import { SamplingLocation } from "../../types/SamplingLocation";
import SpanSeparator from "./SpanSeparator";

interface IProps {
  location: SamplingLocation;
}

export const LocationBreadcrumb: React.FC<IProps> = (props) => {
  const { location } = props;
  return (
    <>
      {["Morava Road", location.section, location.sector, location.km].map(
        (loc, index) => {
          return (
            <React.Fragment key={index}>
              <Text as="span" fontSize="small" color="gray.500">
                {loc}
              </Text>
              {index < 3 && <SpanSeparator />}
            </React.Fragment>
          );
        }
      )}
    </>
  );
};
