import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { envConfig } from "../config/envConfig";
import { Config } from "../types/Config";
import { IAttachedFile } from "../types/IAttachedFile";
import { Parameter } from "../types/Parameter";
import { ParameterSchema } from "../types/ParameterSchema";
import { SamplingData } from "../types/SamplingData";
import { SamplingLocation } from "../types/SamplingLocation";
import { RootState } from "./store";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: envConfig.API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
    mode: "cors",
  }),
  endpoints: (builder) => ({
    readConfig: builder.query<Config, void>({ query: () => "config/" }),
    readParameter: builder.query<
      Parameter[],
      { samplingDataIds: string; parameterSchemaId?: number }
    >({
      query: (params) => ({ url: "parameter", params: params }),
      keepUnusedDataFor: 1,
    }),
    updateParameter: builder.mutation<Parameter[], Parameter[]>({
      query: (body) => ({
        url: "parameter/",
        method: "PUT",
        body: body,
      }),
    }),
    readParameterSchema: builder.query<ParameterSchema[], void>({
      query: () => "parameterSchema/",
    }),
    readSamplingData: builder.query<
      SamplingData[],
      {
        sectors: string[];
        sections: string[];
        date: number;
        categories: string[];
        qualities: string[];
      }
    >({
      query: (params) => {
        return {
          url: "samplingData",
          params: params,
        };
      },
    }),
    readSamplingDataByLocation: builder.query<SamplingData[], number>({
      query: (locationId: number) => `samplingData/${locationId}`,
    }),
    updateSamplingData: builder.mutation<SamplingData, SamplingData>({
      query: (body) => ({
        url: "samplingData/",
        method: "PUT",
        body: body,
      }),
    }),
    readLocations: builder.query<SamplingLocation[], void>({
      query: () => "samplingLocation/",
    }),
    updateLocation: builder.mutation<SamplingLocation, SamplingLocation>({
      query: (body) => ({
        url: "samplingLocation/",
        method: "PUT",
        body: body,
      }),
    }),
    postSignedUrl: builder.mutation<string[], IAttachedFile[]>({
      query: (files) => ({
        url: `postSignedUrl/`,
        method: "POST",
        body: files.map((it) => ({
          key: it.key,
          fileName: encodeURI(it.file !== undefined ? it.file.name : ""),
          contentType: encodeURI(it.file !== undefined ? it.file.type : ""),
        })),
      }),
    }),
    getSignedUrl: builder.query<string[], string[]>({
      query: (keys) => ({
        url: `getSignedUrl`,
        params: { keys: keys },
      }),
    }),
  }),
});
