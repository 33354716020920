import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { LocationBreadcrumb } from "../../../../common/components/LocationBreadcrumb";
import TrafficLight from "../../../../common/components/TrafficLight";
import { AttachedFilesList } from "../../../../common/uploadFiles/AttachedFilesList";
import { UploadFilesRoot } from "../../../../common/uploadFiles/UploadFilesRoot";
import { capitalize } from "../../../../common/utils/capitalize";
import { api } from "../../../../data/api";
import { useAppSelector } from "../../../../data/store";
import { InputExtended } from "../InputExtended";
import "./datepicker.css";
import { useDataForm } from "./useDataForm";

interface NewSamplingDataFormProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NewSamplingDataForm: React.FC<NewSamplingDataFormProps> = (
  props
) => {
  const toast = useToast();
  const {
    form,
    setForm,
    attachedFiles,
    setAttachedFiles,
    updateParameterState,
    postSignedUrlMutationState,
    putFileAWSState,
    updateDataState,
    handleUploadData,
  } = useDataForm();

  const [areCoordinatesValid, setCoordinatesValid] = useState(true);

  // Store
  const auth = useAppSelector((state) => state.auth);
  const config = useAppSelector((state) => state.config.value);
  const mapFilter = useAppSelector((state) => state.mapFilter.value);
  const readLocationsQuery = api.useReadLocationsQuery(undefined, {
    skip: auth.token === undefined,
  });
  const parameterSchema = useAppSelector((state) => state.parameterSchema.list);
  const newSamplingDataView = useAppSelector(
    (state) => state.newSamplingDataView
  );
  // Api
  const newSamplingDataViewParameters = api.useReadParameterQuery(
    {
      samplingDataIds: "" + newSamplingDataView.selectedData?.id!,
      parameterSchemaId: undefined,
    },
    {
      skip:
        auth.token === undefined ||
        newSamplingDataView.selectedData === undefined,
      refetchOnFocus: true,
    }
  );

  // useEffect to display error toast for image upload.
  useEffect(() => {
    if (postSignedUrlMutationState.isError) {
      toast({
        title: "Signed Url error.",
        description: "There was a problem with the server. Please try again.",
        status: "error",
        duration: 4500,
        isClosable: true,
      });
    }
    if (putFileAWSState.isError) {
      toast({
        title: "Upload image error.",
        description: "There was a problem with the server. Please try again.",
        status: "error",
        duration: 4500,
        isClosable: true,
      });
    }
  }, [postSignedUrlMutationState, putFileAWSState, toast]);

  // useEffect to display success toast for parameter data.
  useEffect(() => {
    if (updateParameterState.isSuccess) {
      toast({
        title: "Success.",
        description: "Parameters updated.",
        status: "success",
        duration: 4500,
        isClosable: true,
      });
    }
  }, [toast, updateParameterState]);

  // useEffect to display success toast for sampling data.
  useEffect(() => {
    if (updateDataState.isSuccess) {
      toast({
        title: "Success.",
        description: "Sampling data updated.",
        status: "success",
        duration: 4500,
        isClosable: true,
      });
    }
  }, [toast, updateDataState]);

  // useEffect to display success toast for sampling data and parameters.
  useEffect(() => {
    if (updateDataState.isSuccess && updateParameterState.isSuccess) {
      props.onClose();
      readLocationsQuery.refetch();
      mapFilter.refetch();
    }
  }, [
    readLocationsQuery,
    mapFilter,
    props,
    updateDataState,
    updateParameterState,
  ]);

  // useEffect for error toast for data and parameter.
  useEffect(() => {
    if (updateDataState.isError) {
      toast({
        title: "Error with the sampling data.",
        description: "There was a problem with the server. Please try again.",
        status: "error",
        duration: 4500,
        isClosable: true,
      });
      props.onClose();
    }
    if (updateParameterState.isError) {
      toast({
        title: "Error with the parameters.",
        description: "There was a problem with the server. Please try again.",
        status: "error",
        duration: 4500,
        isClosable: true,
      });
      props.onClose();
    }
  }, [updateDataState, updateParameterState, props, toast]);

  return (
    <Modal
      isCentered
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" px="2rem" pb="0.5rem">
          {newSamplingDataView.selectedLocation ? (
            <>
              <LocationBreadcrumb
                location={newSamplingDataView.selectedLocation}
              />
              <Flex alignItems="center" gap={2}>
                <TrafficLight
                  color={
                    newSamplingDataView.selectedData?.quality === "Bad"
                      ? "primary.light"
                      : newSamplingDataView.selectedData?.quality === "Medium"
                      ? "yellow.light"
                      : "green.light"
                  }
                />
                <Text fontFamily="Times" fontSize="heading3">
                  {newSamplingDataView.selectedLocation.name}
                </Text>
              </Flex>
            </>
          ) : (
            <Spinner />
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="2rem" pb="2rem" overflowY="scroll" maxHeight="80vh">
          {newSamplingDataView.selectedLocation &&
          !updateDataState.isLoading &&
          !updateParameterState.isLoading &&
          (newSamplingDataViewParameters.isSuccess ||
            newSamplingDataViewParameters.isUninitialized) ? (
            <form method="POST">
              <Divider mb={3} />
              <SimpleGrid columns={2} w="100%" gap="1.6rem" fontSize="small">
                <Box w="100%">
                  <Text mb={2}>
                    Sampled date
                    <Text as="span" fontSize="small" color="primary.main">
                      *{" "}
                    </Text>
                  </Text>
                  <DatePicker
                    dateFormat={"dd LLL yyyy"}
                    selected={form.inspectionDate}
                    onChange={(date: Date) =>
                      setForm((state) => ({ ...state, inspectionDate: date }))
                    }
                  />
                </Box>
                <Box w="100%">
                  <Text mb={2}>Laboratory Id</Text>
                  <Input
                    required
                    h="2rem"
                    onChange={(ev) => {
                      const newValue = ev.currentTarget.value;
                      setForm((state) => ({
                        ...state,
                        laboratoryId: newValue,
                      }));
                    }}
                    value={form.laboratoryId}
                  />
                </Box>
                <Box w="100%">
                  <Text mb={2}>
                    Entry date
                    <Text as="span" fontSize="small" color="primary.main">
                      *{" "}
                    </Text>
                  </Text>
                  <DatePicker
                    dateFormat={"dd LLL yyyy"}
                    selected={form.laboratoryDate}
                    onChange={(date: Date) =>
                      setForm((state) => ({ ...state, laboratoryDate: date }))
                    }
                  />
                </Box>

                <Box w="100%">
                  <Text mb={2}>
                    Coordinates
                    <Text as="span" fontSize="small" color="primary.main">
                      *{" "}
                    </Text>
                  </Text>
                  <Input
                    h="2rem"
                    required
                    isInvalid={!areCoordinatesValid}
                    placeholder="00.0000, 00.0000"
                    borderColor={
                      areCoordinatesValid ? undefined : "primary.light"
                    }
                    onChange={(ev) => {
                      const validExpression = ev.currentTarget.value.match(
                        "^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$"
                      );
                      if (validExpression) {
                        setCoordinatesValid(true);
                      } else {
                        setCoordinatesValid(false);
                      }
                      const newValue = ev.currentTarget.value
                        .replace(" ", "")
                        .split(",");
                      setForm((state) => ({
                        ...state,
                        coordinates: { lat: newValue[0], lon: newValue[1] },
                      }));
                    }}
                    value={`${form.coordinates.lat}, ${form.coordinates.lon}`}
                  />
                </Box>
                <Box w="100%">
                  <Text mb={2}>
                    Construction works
                    <Text as="span" fontSize="small" color="primary.main">
                      *{" "}
                    </Text>
                  </Text>
                  <Input
                    required
                    h="2rem"
                    onChange={(ev) => {
                      const newValue = ev.currentTarget.value;
                      setForm((state) => ({
                        ...state,
                        constructionWorksComment: newValue,
                      }));
                    }}
                    value={form.constructionWorksComment}
                  />
                </Box>
                <Box w="100%">
                  <Text mb={2}>
                    Air Temperature
                    <Text as="span" fontSize="small" color="primary.main">
                      *{" "}
                    </Text>
                  </Text>
                  <Input
                    required
                    h="2rem"
                    onChange={(ev) => {
                      const newValue = ev.currentTarget.value;
                      setForm((state) => ({
                        ...state,
                        airTemperature: newValue,
                      }));
                    }}
                    value={form.airTemperature}
                  />
                </Box>
                <Box w="100%">
                  <Text mb={2}>
                    Windspeed
                    <Text as="span" fontSize="small" color="primary.main">
                      *{" "}
                    </Text>
                  </Text>
                  <Input
                    required
                    h="2rem"
                    onChange={(ev) => {
                      const newValue = ev.currentTarget.value;
                      setForm((state) => ({
                        ...state,
                        windSpeed: newValue,
                      }));
                    }}
                    value={form.windSpeed}
                  />
                </Box>
                <Box w="100%">
                  <Text mb={2}>
                    Air Humidity
                    <Text as="span" fontSize="small" color="primary.main">
                      *{" "}
                    </Text>
                  </Text>
                  <Input
                    required
                    h="2rem"
                    onChange={(ev) => {
                      const newValue = ev.currentTarget.value;
                      setForm((state) => ({
                        ...state,
                        airHumidity: newValue,
                      }));
                    }}
                    value={form.airHumidity}
                  />
                </Box>
                <Box w="100%">
                  <Text mb={2}>
                    Air Pressure
                    <Text as="span" fontSize="small" color="primary.main">
                      *{" "}
                    </Text>
                  </Text>
                  <Input
                    required
                    h="2rem"
                    onChange={(ev) => {
                      const newValue = ev.currentTarget.value;
                      setForm((state) => ({
                        ...state,
                        airPressure: newValue,
                      }));
                    }}
                    value={form.airPressure}
                  />
                </Box>
                <Box w="100%">
                  <Text mb={2}>
                    Precipitation
                    <Text as="span" fontSize="small" color="primary.main">
                      *{" "}
                    </Text>
                  </Text>
                  <Input
                    required
                    h="2rem"
                    onChange={(ev) => {
                      const newValue = ev.currentTarget.value;
                      setForm((state) => ({
                        ...state,
                        precipitation: newValue,
                      }));
                    }}
                    value={form.precipitation}
                  />
                </Box>
                <Box w="100%">
                  <Text mb={2}>Extra comment (if needed)</Text>
                  <Textarea
                    h="6.25rem"
                    onChange={(ev) => {
                      const newValue = ev.currentTarget.value;
                      setForm((state) => ({
                        ...state,
                        comment: newValue,
                      }));
                    }}
                    value={form.comment}
                    borderColor="gray.100"
                  />
                </Box>
                <Box w="100%">
                  <Text mb={2}>Drag images (if needed)</Text>
                  <Box height="100%" width="100%">
                    <UploadFilesRoot
                      attachedFiles={attachedFiles}
                      setAttachedFiles={setAttachedFiles}
                      allowedAttachments={[
                        { label: "Image", type: "newUpload" },
                      ]}
                    />
                    <AttachedFilesList
                      attachedFiles={attachedFiles}
                      setAttachedFiles={setAttachedFiles}
                    />
                  </Box>
                </Box>
                {config.classes[newSamplingDataView.selectedLocation.category]
                  .length > 1 && (
                  <Box w="100%">
                    <Text mb={2}>Class</Text>
                    <Select
                      onChange={(event) => {
                        const newValue = event.currentTarget.value;
                        setForm((state) => ({ ...state, class: newValue }));
                      }}
                      value={form.class}
                    >
                      {config.classes[
                        newSamplingDataView.selectedLocation.category
                      ].map((it, index) => {
                        return (
                          <option key={index} value={it}>
                            {it}
                          </option>
                        );
                      })}
                    </Select>
                  </Box>
                )}
                <Box w="100%">
                  <Text mb={2}>Overall performance</Text>
                  <Select
                    onChange={(event) => {
                      const newValue = event.currentTarget.value as
                        | "Bad"
                        | "Medium"
                        | "Good";
                      setForm((state) => ({ ...state, quality: newValue }));
                    }}
                    value={form.quality}
                  >
                    {["Bad", "Medium", "Good"].map((it, index) => {
                      return (
                        <option key={index} value={it}>
                          {it}
                        </option>
                      );
                    })}
                  </Select>
                </Box>
                <Box w="100%">
                  <Text mb={2}>Type of monitoring</Text>
                  <Select
                    onChange={(event) => {
                      const newValue = event.currentTarget.value as
                        | "regular"
                        | "baseline"
                        | "complaint";
                      setForm((state) => ({ ...state, typeOfMonitoring: newValue }));
                    }}
                    value={form.typeOfMonitoring}
                  >
                    {["regular", "baseline", "complaint"].map((it, index) => {
                      return (
                        <option key={index} value={it}>
                          {capitalize(it)}
                        </option>
                      );
                    })}
                  </Select>
                </Box>
                {parameterSchema
                  .filter(
                    (s) =>
                      newSamplingDataView.selectedLocation &&
                      config.parameterSchemaIds[
                        newSamplingDataView.selectedLocation.category
                      ].includes(s.id)
                  )
                  .map((schema, index) => {
                    const value = form.parameters[index]
                      ? form.parameters[index].value
                      : -1;
                    return (
                      <InputExtended
                        label={schema.name}
                        onReset={() => {
                          setForm((state) => ({
                            ...state,
                            parameters: state.parameters.map((it, i) => {
                              if (index === i) {
                                return { ...it, value: "" };
                              } else {
                                return it;
                              }
                            }),
                          }));
                        }}
                        onChange={(ev) => {
                          const newValue = ev.currentTarget.value;
                          setForm((state) => ({
                            ...state,
                            parameters: state.parameters.map((it, i) => {
                              if (index === i) {
                                return { ...it, value: newValue };
                              } else {
                                return it;
                              }
                            }),
                          }));
                        }}
                        value={value || ""}
                        key={index}
                      />
                    );
                  })}
              </SimpleGrid>
              <Divider my={6} />
              <Flex w="100%" justify="space-between">
                <Box as="i">
                  <Text as="span" fontSize="small" color="primary.main">
                    *{" "}
                  </Text>
                  <Text as="span" fontSize="small" color="gray.600">
                    Required fields
                  </Text>
                </Box>
                <Button
                  disabled={!areCoordinatesValid}
                  alignSelf="right"
                  w="50%"
                  variant="solidPrimary"
                  rightIcon={<ArrowForwardIcon />}
                  onClick={handleUploadData}
                >
                  Upload data
                </Button>
              </Flex>
            </form>
          ) : (
            <Flex height="10rem" justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
