import { Box } from "@chakra-ui/react";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useState } from "react";
import MapComponent, { Layer, MapRef, Marker, Source } from "react-map-gl";
import { average } from "../common/utils/average";
import { envConfig } from "../config/envConfig";
import { detailViewSlice } from "../data/detailViewSlice";
import { useAppDispatch, useAppSelector } from "../data/store";
import { SamplingData } from "../types/SamplingData";
import { SamplingLocation } from "../types/SamplingLocation";
import { MapboxPopup } from "./Popups/MapboxPopup";
import { DatesForLocation } from "./Popups/DatesForLocation";
import { useMapboxComponent } from "./useMapboxComponent";
import sec1eksen from "./Sec1-Eksen.json";
import sec1bp from "./Sec1+BP.json";
import sec1pf from "./Sec1+PF.json";
import sec1wa from "./Sec1+WA.json";
import sec2eksen from "./Sec2-Eksen.json";
import sec2bp from "./Sec2-BP.json";
import sec2pf from "./Sec2-PF.json";
import sec2wa from "./Sec2-WA.json";
import sec3eksen from "./Sec3-Eksen.json";
import sec3bp from "./Sec3+BP.json";
import sec3pf from "./Sec3++PF.json";
import sec3wa from "./Sec3+WA.json";

export type ImageType =
  | "circle"
  | "triangle"
  | "square"
  | "pentagon"
  | "star"
  | "hexagon";
export type QualityColor = "green" | "yellow" | "red";

interface IProps {}

export const MapboxComponent: React.FC<IProps> = () => {
  const auth = useAppSelector((state) => state.auth);
  const mapFilter = useAppSelector((state) => state.mapFilter.value);
  const projectConfig = useAppSelector((state) => state.config.value);

  const dispatcher = useAppDispatch();

  const [mapRef, setMapRef] = useState<MapRef | undefined>();
  const mapRefCallback = React.useCallback((ref: MapRef) => {
    if (ref) {
      setMapRef(ref);
    }
  }, []);

  const [viewPort, setViewPort] = useState({
    zoom: 9,
    latitude: projectConfig.defaultCoordinates.lat,
    longitude: projectConfig.defaultCoordinates.lon,
  });

  // Popup
  const [showPopup, setShowPopup] = useState<{
    display: boolean;
    location: SamplingLocation | undefined;
    data: SamplingData | undefined;
  }>({
    display: false,
    location: undefined,
    data: undefined,
  });
  // Dates popup
  const [showDatesPopup, setShowDatesPopup] = useState<{
    display: boolean;
    locationId?: number;
    name?: string;
    lat?: number;
    lon?: number;
  }>({
    display: false,
    name: undefined,
    lat: undefined,
    lon: undefined,
  });

  const { markerDataState, markerLocationState } = useMapboxComponent(
    mapFilter,
    auth
  );

  useEffect(() => {
    const resizeMapbox = (event: UIEvent) => {
      console.log("resize");
      setTimeout(() => mapRef?.resize(), 1000);
    };
    window.addEventListener("resize", resizeMapbox);
    return () => window.removeEventListener("resize", resizeMapbox);
  });

  useEffect(() => {
    if (mapFilter.filteredSamplingData.length > 0) {
      const averageLon = average(
        mapFilter.filteredSamplingData.map((it) => it.coordinates.lon)
      );
      const averageLat = average(
        mapFilter.filteredSamplingData.map((it) => it.coordinates.lat)
      );
      setViewPort((currentViewPort) => ({
        ...currentViewPort,
        longitude: averageLon,
        latitude: averageLat,
      }));
    }
  }, [mapFilter, projectConfig]);

  return (
    <Box
      width="100%"
      height="100%"
      id="map-container"
      position="absolute"
      top="0"
      left="0"
      zIndex="1"
    >
      <MapComponent
        mapStyle="mapbox://styles/dhub/cl2ypoavr000z14n5af53rj77"
        mapboxAccessToken={envConfig.MAPBOX_ACCESS_TOKEN}
        initialViewState={viewPort}
        onClick={(ev) => {
          setShowPopup((state) => ({ ...state, display: false }));
          setShowDatesPopup({
            display: false,
            locationId: undefined,
            name: undefined,
            lat: undefined,
            lon: undefined,
          });
        }}
        ref={mapRefCallback}
      >
        <Source id="sec1eksen" type="geojson" data={sec1eksen as any}>
          <Layer id="sec1eksen" type="line" paint={{ "line-color": "gray" }} />
        </Source>
        <Source id="sec1bp" type="geojson" data={sec1bp as any}>
          <Layer id="sec1bp" type="line" paint={{ "line-color": "gray" }} />
        </Source>
        <Source id="sec1pf" type="geojson" data={sec1pf as any}>
          <Layer id="sec1pf" type="line" paint={{ "line-color": "gray" }} />
        </Source>
        <Source id="sec1wa" type="geojson" data={sec1wa as any}>
          <Layer id="sec1wa" type="line" paint={{ "line-color": "gray" }} />
        </Source>

        <Source id="sec2eksen" type="geojson" data={sec2eksen as any}>
          <Layer id="sec2eksen" type="line" paint={{ "line-color": "gray" }} />
        </Source>
        <Source id="sec2bp" type="geojson" data={sec2bp as any}>
          <Layer id="sec2bp" type="line" paint={{ "line-color": "gray" }} />
        </Source>
        <Source id="sec2pf" type="geojson" data={sec2pf as any}>
          <Layer id="sec2pf" type="line" paint={{ "line-color": "gray" }} />
        </Source>
        <Source id="sec2wa" type="geojson" data={sec2wa as any}>
          <Layer id="sec2wa" type="line" paint={{ "line-color": "gray" }} />
        </Source>

        <Source id="sec3eksen" type="geojson" data={sec3eksen as any}>
          <Layer id="sec3eksen" type="line" paint={{ "line-color": "gray" }} />
        </Source>
        <Source id="sec3bp" type="geojson" data={sec3bp as any}>
          <Layer id="sec3bp" type="line" paint={{ "line-color": "gray" }} />
        </Source>
        <Source id="sec3pf" type="geojson" data={sec3pf as any}>
          <Layer id="sec3pf" type="line" paint={{ "line-color": "gray" }} />
        </Source>
        <Source id="sec3wa" type="geojson" data={sec3wa as any}>
          <Layer id="sec3wa" type="line" paint={{ "line-color": "gray" }} />
        </Source>
        {markerLocationState.map((location, index) => {
          return (
            <Marker
              key={String(location.samplingLocation.id) + index}
              style={{ cursor: "pointer", opacity: 0.5 }}
              longitude={location.longitude}
              latitude={location.latitude}
              onClick={(ev) => {
                console.log("marker clicked");
                ev.originalEvent.stopPropagation();
                setShowDatesPopup({
                  display: true,
                  locationId: location.samplingLocation.id,
                  name: location.samplingLocation.name,
                  lat: location.latitude,
                  lon: location.longitude,
                });
                setShowPopup((state) => ({ ...state, display: false }));
              }}
            >
              <img src={location.src} alt="" width="20px" />
            </Marker>
          );
        })}
        {showDatesPopup.display &&
          showDatesPopup.locationId &&
          showDatesPopup.name &&
          showDatesPopup.lat &&
          showDatesPopup.lon && (
            <DatesForLocation
              locationId={showDatesPopup.locationId}
              name={showDatesPopup.name}
              lat={showDatesPopup.lat}
              lon={showDatesPopup.lon}
              setShow={setShowDatesPopup}
            />
          )}
        {markerDataState.map((data, index) => {
          return (
            <Marker
              key={String(data.samplingLocation.id) + index}
              style={{ cursor: "pointer", zIndex: 1 }}
              longitude={data.longitude}
              latitude={data.latitude}
              onClick={(ev) => {
                ev.originalEvent.stopPropagation();
                setShowPopup({
                  display: true,
                  location: data.samplingLocation,
                  data: data.samplingData,
                });
                setShowDatesPopup({
                  display: false,
                  locationId: undefined,
                  name: undefined,
                  lat: undefined,
                  lon: undefined,
                });
                dispatcher(
                  detailViewSlice.actions.setSelectedLocation(
                    data.samplingLocation
                  )
                );
              }}
            >
              <img src={data.src} alt="" width="20px" />
            </Marker>
          );
        })}
        {showPopup.display && showPopup.location && showPopup.data && (
          <MapboxPopup
            location={showPopup.location}
            samplingData={showPopup.data}
            setShowPopup={setShowPopup}
          />
        )}
      </MapComponent>
    </Box>
  );
};
