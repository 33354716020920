import { Box } from "@chakra-ui/react";
import React from "react";

type SubtitleRedProps = { text: string };

export const SubtitleRed: React.FC<SubtitleRedProps> = (props) => {
  return (
    <Box fontSize="0.75rem" color="primary.main">
      {props.text}
    </Box>
  );
};
