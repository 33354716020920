import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, Grid, GridItem, Select } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import { SmallText } from "../../common/text/SmallText";
import { SubtitleRed } from "../../common/text/SubtitleRed";
import { Title } from "../../common/text/Title";
import { DATES } from "../../config/constants";
import { appViewSlice } from "../../data/appViewSlice";
import { mapFilterSlice } from "../../data/mapFilterSlice";
import { useAppDispatch, useAppSelector } from "../../data/store";

interface IProps {}

export const SamplingDataFilter: React.FC<IProps> = () => {
  const config = useAppSelector((state) => state.config.value);
  const mapFilter = useAppSelector((state) => state.mapFilter.value);

  const dispatcher = useAppDispatch();

  return (
    <Box
      className="Filter"
      padding="2rem"
      borderRadius="0.4rem"
      height="fit-content"
      maxHeight="100%"
      backgroundColor="white"
      overflowY="auto"
      overflowX="hidden"
    >
      <Flex justify="space-between">
        <Box>
          <SubtitleRed text="Morava Road" />
          <Title text="Database" />
        </Box>
        <ArrowBackIcon
          boxSize="1.25rem"
          color="gray.500"
          _hover={{
            cursor: "pointer",
            color: "primary.main",
          }}
          onClick={() => {
            dispatcher(
              mapFilterSlice.actions.set({
                categories: config.categories,
                inspectionDate: DateTime.now().toUnixInteger(),
                filteredSamplingData: [],
                qualities: config.qualities,
                section: "All sections",
                sector: "All sectors",
                refetch: mapFilter.refetch,
              })
            );
            dispatcher(appViewSlice.actions.set({ value: "map" }));
          }}
        />
      </Flex>
      <Divider marginTop="1rem" />
      <Grid
        gap="0rem"
        templateColumns={{ sm: "repeat(2, 1fr)", md: "repeat(1, 1fr)" }}
        columnGap="3rem"
      >
        <GridItem>
          <SmallText text="Sector" />
          <Select
            marginBottom="0.5rem"
            onChange={(event) =>
              dispatcher(
                mapFilterSlice.actions.set({
                  sector: event.currentTarget.value,
                  section: mapFilter.section,
                  inspectionDate: mapFilter.inspectionDate,
                  categories: mapFilter.categories,
                  qualities: mapFilter.qualities,
                  filteredSamplingData: mapFilter.filteredSamplingData,
                  refetch: mapFilter.refetch,
                })
              )
            }
          >
            <option value="All sectors">All sectors</option>
            {config.sectors.map((s, index) => (
              <option key={index} value={s}>
                {s}
              </option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <SmallText text="Section" />
          <Select
            marginBottom="0.5rem"
            onChange={(event) =>
              dispatcher(
                mapFilterSlice.actions.set({
                  sector: mapFilter.sector,
                  section: event.currentTarget.value,
                  inspectionDate: mapFilter.inspectionDate,
                  categories: mapFilter.categories,
                  qualities: mapFilter.qualities,
                  filteredSamplingData: mapFilter.filteredSamplingData,
                  refetch: mapFilter.refetch,
                })
              )
            }
          >
            <option value="All sections">All sections</option>
            {config.sections.map((s, index) => (
              <option key={index} value={s}>
                {s}
              </option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <SmallText text="Sampled date" />
          <Select
            marginBottom="0.5rem"
            onChange={(event) =>
              dispatcher(
                mapFilterSlice.actions.set({
                  sector: mapFilter.sector,
                  section: mapFilter.section,
                  inspectionDate: +event.currentTarget.value,
                  categories: mapFilter.categories,
                  qualities: mapFilter.qualities,
                  filteredSamplingData: mapFilter.filteredSamplingData,
                  refetch: mapFilter.refetch,
                })
              )
            }
            defaultValue={
              DATES.find(
                (it) =>
                  it.month ===
                    DateTime.fromSeconds(mapFilter.inspectionDate).month &&
                  it.year ===
                    DateTime.fromSeconds(mapFilter.inspectionDate).year
              )?.toUnixInteger() || DATES[0].toUnixInteger()
            }
          >
            {DATES.map((date, index) => (
              <option key={index} value={date.toUnixInteger()}>
                {date.toFormat("yyyy LLLL")}
              </option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <SmallText text="Category" />
          <Select
            marginBottom="0.5rem"
            onChange={(event) => {
              dispatcher(
                mapFilterSlice.actions.set({
                  sector: mapFilter.sector,
                  section: mapFilter.section,
                  inspectionDate: mapFilter.inspectionDate,
                  categories:
                    event.currentTarget.value === "All categories"
                      ? config.categories
                      : [event.currentTarget.value],
                  qualities: mapFilter.qualities,
                  filteredSamplingData: mapFilter.filteredSamplingData,
                  refetch: mapFilter.refetch,
                })
              );
            }}
          >
            {["All categories", ...config.categories].map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <SmallText text="Sampling quality" />
          <Select
            marginBottom="0.5rem"
            onChange={(event) => {
              dispatcher(
                mapFilterSlice.actions.set({
                  sector: mapFilter.sector,
                  section: mapFilter.section,
                  inspectionDate: mapFilter.inspectionDate,
                  categories: mapFilter.categories,
                  qualities: [event.currentTarget.value],
                  filteredSamplingData: mapFilter.filteredSamplingData,
                  refetch: mapFilter.refetch,
                })
              );
            }}
          >
            {["All qualities", ...config.qualities].map((quality, index) => (
              <option key={index} value={quality}>
                {quality}
              </option>
            ))}
          </Select>
        </GridItem>
      </Grid>
    </Box>
  );
};
