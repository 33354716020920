import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ParameterSchema } from "../types/ParameterSchema";

interface ParameterSchemaState {
  list: ParameterSchema[];
}

const initialState: ParameterSchemaState = {
  list: [],
};

export const parameterSchemaSlice = createSlice({
  name: "parameterSchema",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<ParameterSchema[]>) => {
      state.list = action.payload;
    },
  },
});
