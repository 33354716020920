import { Box } from "@chakra-ui/react";
import React from "react";

type SmallTextProps = { text: string };

export const SmallText: React.FC<SmallTextProps> = (props) => {
  return (
    <Box
      fontSize="0.75rem"
      lineHeight="1.125rem"
      color="black"
      paddingTop="1rem"
      paddingBottom="1rem"
    >
      {props.text}
    </Box>
  );
};
