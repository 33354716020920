import { Box } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import { api } from "../data/api";
import { authSlice } from "../data/authSlice";
import { configSlice } from "../data/configSlice";
import { detailViewSlice } from "../data/detailViewSlice";
import { mapFilterSlice } from "../data/mapFilterSlice";
import { parameterSchemaSlice } from "../data/parameterSchemaSlice";
import { useAppDispatch, useAppSelector } from "../data/store";
import { Content } from "./Content";
import { Navbar } from "./Navbar";

interface FrameProps {
  children: React.ReactNode;
}
export const ApiProviderFrame: React.FC<FrameProps> = (props) => {
  const dispatcher = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const config = useAppSelector((state) => state.config.value);
  const mapFilter = useAppSelector((state) => state.mapFilter.value);
  const detailViewState = useAppSelector((state) => state.detailView);

  const dataResponse = api.useReadSamplingDataQuery(
    {
      sectors:
        mapFilter.sector === "All sectors"
          ? config.sectors
          : [mapFilter.sector],
      sections:
        mapFilter.section === "All sections"
          ? config.sections
          : [mapFilter.section],
      date: mapFilter.inspectionDate,
      categories: mapFilter.categories,
      qualities: mapFilter.qualities,
    },
    {
      skip: auth.token === undefined,
    }
  );

  const detailViewDataForSelectedLocation =
    api.useReadSamplingDataByLocationQuery(
      detailViewState.selectedLocation?.id!,
      {
        skip:
          auth.token === undefined ||
          detailViewState.selectedLocation === undefined,
      }
    );
  const detailViewParametersForChart = api.useReadParameterQuery(
    {
      samplingDataIds: detailViewState.samplingData
        ? detailViewState
            .samplingData!.map((it) => (it.id ? it.id : -1))
            .toString()
        : "none",
      parameterSchemaId: +detailViewState.parameterNameOrId,
    },
    {
      skip:
        auth.token === undefined ||
        detailViewState.samplingData === undefined ||
        isNaN(Number(detailViewState.parameterNameOrId)),
    }
  );

  const detailViewParametersForTable = api.useReadParameterQuery(
    {
      samplingDataIds: "" + detailViewState.currentSamplingData?.id!,
      parameterSchemaId: undefined,
    },
    {
      skip:
        auth.token === undefined ||
        detailViewState.currentSamplingData === undefined,
    }
  );

  const configResponse = api.useReadConfigQuery(undefined, {
    skip: auth.token === undefined,
  });
  const parameterSchemaResponse = api.useReadParameterSchemaQuery(undefined, {
    skip: auth.token === undefined,
  });

  React.useEffect(() => {
    if (configResponse.data) {
      dispatcher(configSlice.actions.set(configResponse.data));
      dispatcher(
        mapFilterSlice.actions.set({
          ...mapFilter,
          categories: configResponse.data.categories,
          qualities: configResponse.data.qualities,
          section: "All sections",
          sector: "All sectors",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configResponse, dispatcher]);

  React.useEffect(() => {
    if (dataResponse.data) {
      dispatcher(
        mapFilterSlice.actions.set({
          ...mapFilter,
          filteredSamplingData: dataResponse.data,
          refetch: dataResponse.refetch,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataResponse, dispatcher]);

  React.useEffect(() => {
    if (detailViewDataForSelectedLocation.data) {
      dispatcher(
        detailViewSlice.actions.setSamplingData({
          data: detailViewDataForSelectedLocation.data,
          dateUnixTimestamp: mapFilter.inspectionDate,
        })
      );
    }
  }, [detailViewDataForSelectedLocation, dispatcher, mapFilter]);

  React.useEffect(() => {
    if (detailViewParametersForChart.data) {
      dispatcher(
        detailViewSlice.actions.setParameterListChart(
          detailViewParametersForChart.data
        )
      );
    }
  }, [detailViewParametersForChart, dispatcher]);

  React.useEffect(() => {
    if (detailViewParametersForTable.data) {
      dispatcher(
        detailViewSlice.actions.setParameterListTable(
          detailViewParametersForTable.data
        )
      );
    }
  }, [detailViewParametersForTable, dispatcher]);

  React.useEffect(() => {
    if (parameterSchemaResponse.data) {
      dispatcher(
        parameterSchemaSlice.actions.set(parameterSchemaResponse.data)
      );
    }
  }, [parameterSchemaResponse, dispatcher]);

  React.useEffect(() => {
    const getAuthToken = async () => {
      const key = Object.keys(window.localStorage).find((it) =>
        it.includes("idToken")
      );
      if (key) {
        const token = window.localStorage.getItem(key);
        if (token) {
          dispatcher(authSlice.actions.set({ token: token }));
        }
      }
    };
    getAuthToken();
  }, [dispatcher]);

  // This is done to reload when the auth expires
  React.useEffect(() => {
    const intervalId = setInterval(function () {
      if (auth.exp) {
        const expirationDate = DateTime.fromSeconds(auth.exp);
        const now = DateTime.now();
        if (now > expirationDate) {
          console.warn(
            "Authentication expired. You will be redirected to the login screen."
          );
          window.localStorage.clear(); //try this to clear all local storage
          window.location.reload();
        }
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [auth.exp]);

  return (
    <Box width="100%" height="100%">
      <Navbar />
      <Content>{props.children}</Content>
    </Box>
  );
};
