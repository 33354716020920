import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const uploadApiAWS = createApi({
  reducerPath: "api-aws",
  baseQuery: fetchBaseQuery({ baseUrl: "" }),
  endpoints: (builder) => ({
    putFile: builder.mutation<
      string,
      { signedUrl: string; contentType: string; file: File }
    >({
      query: (params) => ({
        url: `${params.signedUrl}`,
        method: "PUT",
        body: params.file,
        headers: { "Content-Type": params.contentType },
      }),
    }),
  }),
});
