import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { BsDownload } from "react-icons/bs";

import { LocationBreadcrumb } from "../../../common/components/LocationBreadcrumb";
import TrafficLight from "../../../common/components/TrafficLight";
import { appViewSlice } from "../../../data/appViewSlice";
import {
  detailViewSlice,
  DetailViewState,
} from "../../../data/detailViewSlice";
import { useAppDispatch, useAppSelector } from "../../../data/store";
import { MapFilter } from "../../../types/MapFilter";
import { ParameterSchema } from "../../../types/ParameterSchema";
import LineChart from "./components/LineChart";
import { getChartData } from "./util/getChartData";
import { getChartDataFromParams } from "./util/getChartDataFromParams";

interface IProps {
  setShowPrint: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getLatestDataQuality = (
  detailViewState: DetailViewState,
  mapFilter: MapFilter
) => {
  if (!detailViewState.selectedLocation) {
    throw new Error("Selected location is undefined.");
  }
  if (!detailViewState.selectedLocation.id) {
    throw new Error("Selected location id is undefined.");
  }
  if (
    detailViewState.selectedLocation &&
    detailViewState.samplingData &&
    detailViewState.samplingData.length > 1
  ) {
    const dataGivenDate = detailViewState.samplingData.find(
      (it) => it.inspectionDate === mapFilter.inspectionDate
    );
    if (dataGivenDate) {
      return dataGivenDate;
    }
    // If the date from the mapFilter does not correspond with a date of any of the sampling data
    // Then return the oldest sampling data.
    const sorted = [...detailViewState.samplingData].sort(
      (a, b) => b.inspectionDate - a.inspectionDate
    );
    if (sorted.length > 0) return sorted[0].quality;
  } else if (
    detailViewState.selectedLocation &&
    detailViewState.samplingData &&
    detailViewState.samplingData.length === 1
  ) {
    return detailViewState.samplingData[0].quality;
  }
  return "Medium";
};

const DetailChart: React.FC<IProps> = (props) => {
  const parameterSchema = useAppSelector((state) => state.parameterSchema.list);
  const dispatcher = useAppDispatch();
  const detailViewState = useAppSelector((state) => state.detailView);
  const mapFilter = useAppSelector((state) => state.mapFilter.value);

  const schema: ParameterSchema | undefined = parameterSchema.find(
    (it) => it.id + "" === detailViewState.parameterNameOrId
  );

  const detailStateInitialized =
    detailViewState.parameterListChart &&
    detailViewState.samplingData &&
    detailViewState.currentSamplingData;

  return (
    <Box
      className="DetailChart"
      padding="2rem"
      borderRadius="0.4rem"
      bgColor="white"
      overflowY="auto"
      overflowX="hidden"
      minHeight="31rem"
      height="100%"
      width="100%"
    >
      {detailViewState.selectedLocation &&
        detailViewState.selectedLocation.id && (
          <>
            <Flex justify="space-between">
              <Box>
                <LocationBreadcrumb
                  location={detailViewState.selectedLocation}
                />
              </Box>
              <Flex gap={3}>
                <ArrowBackIcon
                  boxSize="1.25rem"
                  color="gray.500"
                  _hover={{
                    cursor: "pointer",
                    color: "primary.main",
                  }}
                  onClick={() => {
                    dispatcher(detailViewSlice.actions.reset());
                    dispatcher(appViewSlice.actions.set({ value: "map" }));
                  }}
                />
                <Icon
                  as={BsDownload}
                  boxSize="1.25rem"
                  color="gray.500"
                  onClick={() => {
                    props.setShowPrint(true);
                    setTimeout(() => {
                      window.print();
                      props.setShowPrint(false);
                    }, 1000);
                  }}
                  _hover={{
                    cursor: "pointer",
                    color: "primary.main",
                  }}
                />
              </Flex>
            </Flex>

            <Flex alignItems="center" gap={2}>
              <TrafficLight
                color={
                  getLatestDataQuality(detailViewState, mapFilter) === "Bad"
                    ? "primary.light"
                    : getLatestDataQuality(detailViewState, mapFilter) ===
                      "Medium"
                    ? "yellow.light"
                    : "green.light"
                }
              />
              <Text fontFamily="Times" fontSize="heading3">
                {detailViewState.selectedLocation.name}
              </Text>
            </Flex>
            <Divider my={3} />
            <Text fontSize="0.875rem" fontWeight="400" mb={3}>
              {detailViewState.parameterNameOrId === "Overall performance"
                ? "Overall performance"
                : parameterSchema.find(
                    (it) => it.id + "" === detailViewState.parameterNameOrId
                  )?.name}
            </Text>
            <Box width="100%" height="calc(100% - 8rem)" position="relative">
              <LineChart
                data={
                  detailStateInitialized &&
                  detailViewState.parameterNameOrId === "Overall performance"
                    ? getChartData(detailViewState.samplingData || [])
                    : getChartDataFromParams(
                        detailViewState.parameterListChart,
                        detailViewState.parameterNameOrId,
                        detailViewState.samplingData!,
                        schema?.reference[
                          detailViewState.currentSamplingData!.class
                        ],
                        schema?.typeOfReference
                      )
                }
                parameterNameOrId={detailViewState.parameterNameOrId}
              />
            </Box>
          </>
        )}
    </Box>
  );
};
export default DetailChart;
