import { DateTime } from "luxon";
import { Parameter } from "../../../../types/Parameter";
import { SamplingData } from "../../../../types/SamplingData";
import { ChartDataXY } from "../components/LineChart";

export const getChartDataFromParams = (
  parameterList: Parameter[],
  parameterNameOrId: string,
  allSamplingData: SamplingData[],
  reference?: number[],
  typeOfReference?: "range" | "min" | "max" | "none"
): { green: ChartDataXY[]; red: ChartDataXY[]; yellow: ChartDataXY[] } => {
  if (reference === undefined || typeOfReference === undefined) {
    return { green: [], red: [], yellow: [] };
  }

  const xyData: {
    x: DateTime;
    y: number;
  }[] = parameterList
    .filter(
      (param) =>
        String(param.parameterSchemaId) === parameterNameOrId &&
        param.value !== ""
    )
    .map((param) => {
      const samplingData = allSamplingData.find(
        (it) => it.id === param.samplingDataId
      );
      if (samplingData === undefined)
        throw new Error("Cant find sampling data for current parameter.");
      return {
        x: DateTime.fromSeconds(samplingData.inspectionDate),
        y: Number(
          param.value
            .split("±")[0]
            .replaceAll("<", "")
            .replaceAll(">", "")
            .replaceAll("%", "")
        ),
      };
    });

  const res: {
    x: DateTime;
    y: number;
  }[] = [];

  xyData
    .sort((a, b) => a.x.toUnixInteger() - b.x.toUnixInteger())
    .forEach((it, index) => {
      res.push(it);
      const next = xyData[index + 1];
      console.log("forEach", next);
      if (next) {
        const jump = next.x.month - it.x.month;
        if (jump > 1) {
          console.log("jump");
          const nextMonth = it.x.plus({ month: 1 });
          res.push({ x: nextMonth, y: NaN });
        }
      }
    });

  console.log({ res, xyData, parameterList });

  if (res.length && res[0]) {
    return {
      green: res.filter(filterBy(reference, typeOfReference)),
      red: res.filter((it) => !filterBy(reference, typeOfReference)(it)),
      yellow: [],
    };
  } else {
    return { green: [], red: [], yellow: [] };
  }
};

const filterBy = (
  reference: number[],
  typeOfReference: "range" | "min" | "max" | "none"
) => {
  return (it: { x: DateTime; y: number }) => {
    if (typeOfReference === "range") {
      return reference[0] < it.y && it.y < reference[1];
    } else if (typeOfReference === "max") {
      return reference[0] > it.y;
    } else if (typeOfReference === "min") {
      return reference[0] < it.y;
    } else {
      return false;
    }
  };
};
