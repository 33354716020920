import { AddIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import TrafficLight from "../../common/components/TrafficLight";
import getTrafficColor from "../../common/utils/getTrafficColor";
import { api } from "../../data/api";
import { newSamplingDataViewSlice } from "../../data/newSamplingDataViewSlice";
import { useAppDispatch, useAppSelector } from "../../data/store";
import AddForm from "./components/AddForm";
import { NewSamplingDataForm } from "./components/NewSamplingDataForm/NewSamplingDataForm";
import NewSamplingLocationForm from "./components/NewSamplingLocationForm";

interface IProps {}

const SamplingDataTable: React.FC<IProps> = () => {
  const auth = useAppSelector((state) => state.auth);

  const {
    isOpen: isOpenNewSamplingLocation,
    onOpen: onOpenNewSamplingLocation,
    onClose: onCloseNewSamplingLocation,
  } = useDisclosure();
  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();
  const {
    isOpen: isOpenNewSamplingData,
    onOpen: onOpenNewSamplingData,
    onClose: onCloseNewSamplingData,
  } = useDisclosure();
  const dispatcher = useAppDispatch();
  const filteredSamplingData = useAppSelector(
    (state) => state.mapFilter.value.filteredSamplingData
  );
  const readLocationsQuery = api.useReadLocationsQuery(undefined, {
    skip: auth.token === undefined,
  });

  return (
    <Box
      className="scroll-shadows"
      padding="2rem"
      borderRadius="0.4rem"
      height="100%"
      maxH="100%"
      bgColor="white"
      overflowY="scroll"
      w="100%"
    >
      {auth.groups &&
        auth.groups.length > 0 &&
        auth.groups.find((group) => group.split(":").includes("create")) && (
          <IconButton
            float="right"
            aria-label="New sampling"
            maxWidth="2.5rem"
            maxHeight="2.5rem"
            height="2.5rem"
            borderRadius="50%"
            bgColor="primary.main"
            color="white"
            _hover={{ bgColor: "primary.light" }}
            icon={<AddIcon />}
            onClick={() => {
              dispatcher(newSamplingDataViewSlice.actions.setData(undefined));
              onOpenAddModal();
            }}
          />
        )}
      <Table variant="simple" fontSize="small">
        <Thead>
          <Tr>
            <Th fontWeight="400">Point</Th>
            <Th fontWeight="400">Section</Th>
            <Th fontWeight="400">Sector</Th>
            <Th fontWeight="400">Km</Th>
            <Th fontWeight="400">Category</Th>
            <Th fontWeight="400">Sampled date</Th>
            <Th fontWeight="400">Entry date</Th>
            <Th fontWeight="400">Quality</Th>
            <Th fontWeight="400" />
          </Tr>
        </Thead>
        <Tbody>
          {Object.values(filteredSamplingData)
            .flatMap((it) => it)
            .map((sample, index) => {
              const currentLocation = readLocationsQuery.data?.find(
                (l) => l.id === sample.locationId
              );
              return (
                <Tr key={index} fontWeight="bold">
                  <Td>{currentLocation?.name}</Td>
                  <Td fontWeight="normal">{currentLocation?.sector}</Td>
                  <Td fontWeight="normal">{currentLocation?.section}</Td>
                  <Td fontWeight="normal">{currentLocation?.km}</Td>
                  <Td>{currentLocation?.category}</Td>
                  <Td>
                    {DateTime.fromSeconds(sample.inspectionDate).toFormat(
                      "dd LLL yyyy"
                    )}
                  </Td>
                  <Td>
                    {DateTime.fromSeconds(sample.laboratoryDate).toFormat(
                      "dd LLL yyyy"
                    )}
                  </Td>
                  <Td>
                    <TrafficLight
                      color={getTrafficColor(sample.quality)}
                      diameter={1.25}
                    />
                  </Td>
                  <Td textAlign="right">
                    {auth.groups &&
                      auth.groups.length > 0 &&
                      auth.groups.find((group) =>
                        group.split(":").includes("write")
                      ) && (
                        <Button
                          variant="solidSecondary"
                          rightIcon={<ArrowForwardIcon />}
                          onClick={() => {
                            dispatcher(
                              newSamplingDataViewSlice.actions.setLocation(
                                currentLocation
                              )
                            );
                            dispatcher(
                              newSamplingDataViewSlice.actions.setData(sample)
                            );
                            onOpenNewSamplingData();
                          }}
                        >
                          Edit
                        </Button>
                      )}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      {isOpenAddModal && (
        <AddForm
          isOpen={isOpenAddModal}
          onClose={onCloseAddModal}
          onOpenNewSamplingLocation={onOpenNewSamplingLocation}
          onOpenNewSamplingData={onOpenNewSamplingData}
        />
      )}

      {isOpenNewSamplingLocation && (
        <NewSamplingLocationForm
          isOpen={isOpenNewSamplingLocation}
          onClose={onCloseNewSamplingLocation}
          onOpenNext={onOpenNewSamplingData}
        />
      )}

      {isOpenNewSamplingData && (
        <NewSamplingDataForm
          isOpen={isOpenNewSamplingData}
          onClose={onCloseNewSamplingData}
        />
      )}
    </Box>
  );
};
export default SamplingDataTable;
