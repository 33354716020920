import React from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
  color?: string /** @param {string} color Background color for the indicator (default green)*/;
  diameter?: number /** @param {number} diameter Size of the diameter (in rem units, default 1)*/;
  outline?: boolean /**  @param {boolean} outline Boolean that states whether the traffic light has an outer circle or doesn't (default false)*/;
}

const TrafficLight: React.FC<IProps> = (props) => {
  const { color, diameter = 1, outline = false } = props;
  return (
    <Box
      bgColor={color}
      w={`${diameter}rem`}
      h={`${diameter}rem`}
      borderRadius="50%"
      outline={outline ? "1px solid" : undefined}
      outlineColor={outline ? "black.200" : undefined}
      p={outline ? "0.05rem" : undefined}
      outlineOffset={outline ? "0.1rem" : undefined}
      backgroundClip={outline ? "content-box" : undefined}
    />
  );
};
export default TrafficLight;
