import { DateTime } from "luxon";

export const MIN_DATE = DateTime.fromISO("2022-01-01T00:00:00Z");
export const MAX_DATE = DateTime.fromISO("2024-12-01T00:00:00Z");
export const MIN_DATE_2019 = DateTime.fromISO("2019-08-01T00:00:00Z");

export const DATES: DateTime[] = [
  MIN_DATE_2019,
  DateTime.fromISO("2019-09-01T00:00:00Z"),
  DateTime.fromISO("2019-10-01T00:00:00Z"),
  DateTime.fromISO("2019-11-01T00:00:00Z"),
  DateTime.fromISO("2019-12-01T00:00:00Z"),
].concat(
  new Array(36).fill(0).map((_it, index) => {
    return MIN_DATE.plus({ months: index }).startOf("month").plus({ days: 1 });
  })
);
