import React from "react";
import { AuthenticationProvider } from "@arup-group/cognito-authenticator";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";

import { ApiProviderFrame } from "./common/ApiProviderFrame";
import { envConfig } from "./config/envConfig";
import theme from "./config/theme";
import { store } from "./data/store";
import { MapRoot } from "./mapView/MapRoot";
import { DateTime } from "luxon";
import { getExpirationDate } from "./common/auth/utils";

export const App = () => {
  React.useEffect(() => {
    const key = Object.keys(window.localStorage).find((it) =>
      it.includes("idToken")
    );
    if (key) {
      const token = window.localStorage.getItem(key);
      if (token) {
        const expirationDate = getExpirationDate(token);
        const now = DateTime.now();
        if (expirationDate && Math.floor(now.toSeconds()) > expirationDate) {
          window.localStorage.clear();
          window.location.reload();
        }
      }
    }
  }, []);

  return (
    <>
      <AuthenticationProvider
        region={envConfig.AWS_REGION}
        userPoolId={envConfig.AWS_POOL_ID}
        userPoolWebClientId={envConfig.AWS_WEB_CLIENT_ID}
        domain={envConfig.AWS_COGNITO_DOMAIN}
        azureADProviderId={envConfig.AWS_AZURE_PROVIDER_ID}
        allowSignUp={false}
      >
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            <Box
              className="app-box"
              height="100vh"
              width="100vw"
              overflow={{ base: "scroll", sm: "hidden" }}
            >
              <ApiProviderFrame>
                <MapRoot />
              </ApiProviderFrame>
            </Box>
          </ChakraProvider>
        </Provider>
      </AuthenticationProvider>
    </>
  );
};
