import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
	baseStyle: {
		control: {
			borderColor: "gray.500",
			_checked: {
				bg: "gray.500",
				borderColor: "gray.500",
			},
		},
		label: {
			color: "gray.600",
		},
	},
	variants: {
		primary: {
			control: {
				_checked: {
					bg: "primary.main",
					borderColor: "primary.main",
				},
			},
		},
	},
	sizes: {
		md: {
			label: {
				fontSize: "small",
			},
		},
	},
	defaultProps: {
		size: "md",
	},
};

export default componentOverride;
