import { Button, Input, Tooltip } from "@chakra-ui/react";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { IAttachedFile } from "../../types/IAttachedFile";
import { IAllowedAttachment } from "./UploadFilesRoot";

interface IProps {
  allowedAttachment: IAllowedAttachment;
  insertSelectedFile: (file: IAttachedFile | IAttachedFile[]) => void;
}

const UploadFile: React.FC<IProps> = (props) => {
  const { allowedAttachment, insertSelectedFile } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef: any = React.useRef();
  const onLinkClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Button
        onClick={onLinkClick}
        fontSize="small"
        width="100%"
        variant="outlineTransparent"
      >
        <Tooltip
          label="Hold CTRL key for selecting more than 1 file at once"
          aria-label="Upload new files instructions"
        >
          Upload image
        </Tooltip>
      </Button>
      <Input
        ref={inputRef}
        type="file"
        multiple={true}
        onChange={(event) => {
          if (event.target.files) {
            insertSelectedFile(
              Array.from(event.target.files).map((file) => ({
                name: file.name,
                file,
                type: allowedAttachment.type,
                key: `${uuidv4()}.${file.name.split(".")[1]}`,
              }))
            );
          }
        }}
        visibility="hidden"
        w="0.01px"
        h="0.01px"
        position="absolute"
        top="0px"
        left="-50%"
      />
    </>
  );
};

export default UploadFile;
