import {
  Box,
  Divider,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import { envConfig } from "../../config/envConfig";
import { useAppSelector } from "../../data/store";
import LineChart from "../../mapView/Detail/DetailChart/components/LineChart";
import { getLatestDataQuality } from "../../mapView/Detail/DetailChart/DetailChart";
import { getChartData } from "../../mapView/Detail/DetailChart/util/getChartData";
import { getChartDataFromParams } from "../../mapView/Detail/DetailChart/util/getChartDataFromParams";
import { Parameter } from "../../types/Parameter";
import { ParameterSchema } from "../../types/ParameterSchema";
import { LocationBreadcrumb } from "../components/LocationBreadcrumb";
import SpanSeparator from "../components/SpanSeparator";
import TrafficLight from "../components/TrafficLight";
import { capitalize } from "../utils/capitalize";
// <style>{`@media screen {.print{display: none;}}`}</style>
// <style>{`@media print {.certification-item-link{display: none;}}`}</style>
// <style>{`@media print {.print{-webkit-print-color-adjust: exact !important; color-adjust: exact !important;}}`}</style>

export const Print: React.FC = () => {
  const config = useAppSelector((state) => state.config.value);
  const mapFilter = useAppSelector((state) => state.mapFilter.value);
  const parameterSchema = useAppSelector((state) => state.parameterSchema.list);
  const detailViewState = useAppSelector((state) => state.detailView);
  const schema: ParameterSchema | undefined = parameterSchema.find(
    (it) => it.id + "" === detailViewState.parameterNameOrId
  );

  if (
    detailViewState === undefined ||
    detailViewState.currentSamplingData === undefined ||
    detailViewState.selectedLocation === undefined
  ) {
    return <Spinner />;
  }

  return (
    <>
      {/* {!true && (
        <style>{`@media print {.${props.className}{display: none;}}`}</style>
      )}
      {!true && (
        <style>{`@media screen {.${props.className}{display: none;}}`}</style>
      )}
      {true && (
        <style>{`@media print {.${props.className}{-webkit-print-color-adjust: exact !important; color-adjust: exact !important;}}`}</style>
      )} */}
      <style>{`@media print {map-container{display: none;}}`}</style>
      <style>{`@media print {.dont-print{display: none;}}`}</style>
      <style>{`@media print {.app-box{-webkit-print-color-adjust: exact !important; color-adjust: exact !important;}}`}</style>
      <style>{`@media print {.print{height:100vh !important}`}</style>
      <Box
        className="print"
        position="absolute"
        backgroundColor="white"
        top={0}
        width="100%"
        minHeight="200vh"
        zIndex={1000}
      >
        <Flex height="5rem" alignItems="flex-start" flexDirection="row">
          <Text fontSize="heading3" fontFamily="heading" color="primary.main">
            d.Hub
          </Text>
          <Text fontSize="heading3" fontFamily="heading" marginLeft={1}>
            {envConfig.APP_NAME}
          </Text>
        </Flex>
        <Divider my={3} />
        <LocationBreadcrumb location={detailViewState.selectedLocation} />
        <Flex alignItems="center" gap={2}>
          <TrafficLight
            color={
              getLatestDataQuality(detailViewState, mapFilter) === "Bad"
                ? "primary.light"
                : getLatestDataQuality(detailViewState, mapFilter) === "Medium"
                ? "yellow.light"
                : "green.light"
            }
          />
          <Text fontFamily="Times" fontSize="heading3">
            {detailViewState.selectedLocation.name}
          </Text>
        </Flex>
        <Text fontFamily="Times">
          Laboratory Id: {detailViewState.currentSamplingData.laboratoryId}/
          Entry date:{" "}
          {DateTime.fromSeconds(
            detailViewState.currentSamplingData.laboratoryDate
          ).toFormat("dd LLL yyyy")}
        </Text>
        <Text fontFamily="Times">
          Type of monitoring:{" "}
          {capitalize(detailViewState.currentSamplingData.typeOfMonitoring)}
        </Text>
        <Divider my={3} />
        <Text fontSize="small" mb={3}>
          {detailViewState.parameterNameOrId === "Overall performance"
            ? "Overall performance"
            : parameterSchema.find(
                (it) => it.id + "" === detailViewState.parameterNameOrId
              )?.name}
        </Text>
        {/* Chart */}
        <Box width="100%" height="600px" position="relative">
          <LineChart
            data={
              detailViewState.parameterListChart &&
              detailViewState.samplingData &&
              detailViewState.currentSamplingData &&
              detailViewState.parameterNameOrId === "Overall performance"
                ? getChartData(detailViewState.samplingData)
                : getChartDataFromParams(
                    detailViewState.parameterListChart,
                    detailViewState.parameterNameOrId,
                    detailViewState.samplingData!,
                    schema?.reference[
                      detailViewState.currentSamplingData!.class
                    ],
                    schema?.typeOfReference
                  )
            }
            parameterNameOrId={detailViewState.parameterNameOrId}
          />
        </Box>
        <Divider my={3} />

        {/* Parameters */}
        <Box>
          <Text as="span" fontSize="small" color="primary.main">
            {DateTime.fromSeconds(mapFilter.inspectionDate).toFormat(
              "dd LLL yyyy"
            )}
          </Text>
          {config.classes[detailViewState.selectedLocation.category].length >
            1 && (
            <>
              <SpanSeparator />
              <Text as="span" fontSize="small" color="primary.main">
                {detailViewState.currentSamplingData.class}
              </Text>
            </>
          )}
        </Box>
        <Flex flexDirection="row" gap="1rem">
          <Box height="1210px" width="50%">
            <Table>
              <Thead height="3rem">
                <Tr>
                  <Th fontWeight="400">Parameter</Th>
                  <Th fontWeight="400">Ref.</Th>
                  <Th fontWeight="400">Value</Th>
                  <Th fontWeight="400">Unit</Th>
                  <Th fontWeight="400" textAlign="right">
                    Quality
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td colSpan={4} fontWeight="bold">
                    {detailViewState.currentSamplingData?.quality}
                  </Td>
                  <Td>
                    <Box w="fit-content" ml="auto" mr="0">
                      <TrafficLight
                        color={
                          detailViewState.currentSamplingData.quality === "Bad"
                            ? "primary.main"
                            : detailViewState.currentSamplingData.quality ===
                              "Medium"
                            ? "yellow.light"
                            : "green.light"
                        }
                        outline={
                          detailViewState.parameterNameOrId ===
                          "Overall performance"
                            ? true
                            : false
                        }
                      />
                    </Box>
                  </Td>
                </Tr>
                {detailViewState.currentSamplingData &&
                  detailViewState.parameterListTable
                    .slice(
                      0,
                      Math.floor(detailViewState.parameterListTable.length / 2)
                    )
                    .map((it: Parameter, index: number) => {
                      const schema = parameterSchema.find(
                        (pSchema) => it.parameterSchemaId === pSchema.id
                      );
                      if (schema) {
                        return (
                          <Tr key={index}>
                            <Td>{schema.name}</Td>
                            <Td>
                              {detailViewState.currentSamplingData &&
                                schema.reference[
                                  detailViewState.currentSamplingData.class
                                ].toString()}
                            </Td>
                            <Td fontWeight="bold">{it.value}</Td>
                            <Td>{schema.unit}</Td>
                            <Td>
                              <Box w="fit-content" ml="auto" mr="0">
                                <TrafficLight
                                  color={
                                    it.quality === "Bad"
                                      ? "primary.main"
                                      : it.quality === "Medium"
                                      ? "yellow.light"
                                      : "green.light"
                                  }
                                  outline={
                                    detailViewState.parameterNameOrId ===
                                    schema.id + ""
                                      ? true
                                      : false
                                  }
                                />
                              </Box>
                            </Td>
                          </Tr>
                        );
                      } else {
                        return <></>;
                      }
                    })}
              </Tbody>
            </Table>
          </Box>
          <Box height="1210px" width="50%">
            <Table>
              <Thead>
                <Tr height="3rem"></Tr>
              </Thead>
              <Tbody>
                {detailViewState.currentSamplingData &&
                  detailViewState.parameterListTable
                    .slice(
                      Math.floor(detailViewState.parameterListTable.length / 2),
                      detailViewState.parameterListTable.length
                    )
                    .map((it: Parameter, index: number) => {
                      const schema = parameterSchema.find(
                        (pSchema) => it.parameterSchemaId === pSchema.id
                      );
                      if (schema) {
                        return (
                          <Tr key={index}>
                            <Td>{schema.name}</Td>
                            <Td>
                              {detailViewState.currentSamplingData &&
                                schema.reference[
                                  detailViewState.currentSamplingData.class
                                ].toString()}
                            </Td>
                            <Td fontWeight="bold">{it.value}</Td>
                            <Td>{schema.unit}</Td>
                            <Td>
                              <Box w="fit-content" ml="auto" mr="0">
                                <TrafficLight
                                  color={
                                    it.quality === "Bad"
                                      ? "primary.main"
                                      : it.quality === "Medium"
                                      ? "yellow.light"
                                      : "green.light"
                                  }
                                  outline={
                                    detailViewState.parameterNameOrId ===
                                    schema.id + ""
                                      ? true
                                      : false
                                  }
                                />
                              </Box>
                            </Td>
                          </Tr>
                        );
                      } else {
                        return <></>;
                      }
                    })}
              </Tbody>
            </Table>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
