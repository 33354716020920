import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { MapFilter } from "../types/MapFilter";

export interface MapFilterState {
  value: MapFilter;
}

// Define the initial state using that type
const initialState: MapFilterState = {
  value: {
    sector: "All sectors",
    section: "All sections",
    inspectionDate: DateTime.now().endOf("month").toUnixInteger(),
    categories: [],
    qualities: [],
    filteredSamplingData: [],
    refetch: () => {},
  },
};

export const mapFilterSlice = createSlice({
  name: "mapFilter",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<MapFilter>) => {
      state.value = {
        filteredSamplingData: action.payload.filteredSamplingData,
        inspectionDate: action.payload.inspectionDate,
        categories: action.payload.categories,
        qualities: action.payload.qualities,
        section: action.payload.section,
        sector: action.payload.sector,
        refetch: action.payload.refetch,
      };
    },
  },
});
