import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { IAttachedFile } from "../../types/IAttachedFile";
import UploadFile from "./UploadFile";

type AttachmentType = "newUpload" | "selection" | "innerLink";

export interface IAllowedAttachment {
  label: string;
  type: AttachmentType;
  options?: IAttachedFile[];
}
interface IProps {
  attachedFiles: IAttachedFile[];
  setAttachedFiles: (attachedFiles: IAttachedFile[]) => void;
  allowedAttachments: IAllowedAttachment[];
}

export const UploadFilesRoot: React.FC<IProps> = (props) => {
  const insertSelectedFile = (file: IAttachedFile | IAttachedFile[]) => {
    if (
      !Array.isArray(file) &&
      props.attachedFiles.findIndex((f) => f.key === file.key) === -1
    )
      return props.setAttachedFiles([...props.attachedFiles, file]);
    else if (Array.isArray(file))
      props.setAttachedFiles([
        ...props.attachedFiles,
        ...file.filter(
          (f) => !props.attachedFiles.map((aF) => aF.name).includes(f.name)
        ),
      ]);
  };

  return (
    <Box
      className="UploadFilesRoot"
      height="6.25rem"
      width="100%"
      border="solid 1px"
      borderColor="gray.100"
      borderRadius="0.5rem"
    >
      <Flex
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
				overflowY="auto"
      >
        {props.allowedAttachments
          .filter((x) => x.type === "newUpload")
          .map((allowedAttachment, index) => (
            <UploadFile
              key={`uploadFile_${index}`}
              insertSelectedFile={insertSelectedFile}
              allowedAttachment={allowedAttachment}
            />
          ))}
      </Flex>
    </Box>
  );
};
