import { useEffect, useState } from "react";
import { api } from "../../../../data/api";
import { useAppSelector } from "../../../../data/store";
import { uploadApiAWS } from "../../../../data/uploadApiAWS";
import { IAttachedFile } from "../../../../types/IAttachedFile";
import { SamplingData } from "../../../../types/SamplingData";
import { FormType } from "./FormType";
import { initialForm } from "./initialForm";

export const useDataForm = () => {
  const [form, setForm] = useState<FormType>({ ...initialForm });
  const [attachedFiles, setAttachedFiles] = useState<IAttachedFile[]>([]);

  const newSamplingDataView = useAppSelector(
    (state) => state.newSamplingDataView
  );
  const config = useAppSelector((state) => state.config.value);
  const auth = useAppSelector((state) => state.auth);
  const parameterSchema = useAppSelector((state) => state.parameterSchema.list);

  const newSamplingDataViewParameters = api.useReadParameterQuery(
    {
      samplingDataIds: "" + newSamplingDataView.selectedData?.id!,
      parameterSchemaId: undefined,
    },
    {
      skip:
        auth.token === undefined ||
        newSamplingDataView.selectedData === undefined,
      refetchOnFocus: true,
    }
  );
  const [updateParameter, updateParameterState] =
    api.useUpdateParameterMutation();
  const [updateData, updateDataState] = api.useUpdateSamplingDataMutation();
  const [postSignedUrlMutation, postSignedUrlMutationState] =
    api.usePostSignedUrlMutation();
  const [putFileAWS, putFileAWSState] = uploadApiAWS.usePutFileMutation();

  useEffect(() => {
    if (newSamplingDataView.selectedLocation) {
      const currentLocation = newSamplingDataView.selectedLocation;
      setForm((state) => ({
        ...state,
        coordinates: {
          lat: "" + currentLocation.defaultCoordinates.lat,
          lon: "" + currentLocation.defaultCoordinates.lon,
        },
        class: config.classes[currentLocation.category][0],
      }));
    }
    if (newSamplingDataView.selectedData) {
      const currentData = newSamplingDataView.selectedData;
      setForm((state) => ({
        ...state,
        id: currentData.id,
        laboratoryId: currentData.laboratoryId,
        class: currentData.class,
        comment: currentData.comment,
        constructionWorksComment: currentData.constructionWorksComment,
        coordinates: {
          lat: "" + currentData.coordinates.lat,
          lon: "" + currentData.coordinates.lon,
        },
        inspectionDate: new Date(currentData.inspectionDate * 1000),
        laboratoryDate: new Date(currentData.laboratoryDate * 1000),
        images: [],
        quality: currentData.quality,
        typeOfMonitoring: currentData.typeOfMonitoring,
        airTemperature: currentData.airTemperature,
        windSpeed: currentData.windSpeed,
        airHumidity: currentData.airHumidity,
        airPressure: currentData.airPressure,
        precipitation: currentData.precipitation,
        parameters: parameterSchema
          .filter(
            (s) =>
              newSamplingDataView.selectedLocation &&
              config.parameterSchemaIds[
                newSamplingDataView.selectedLocation.category
              ].includes(s.id)
          )
          .map((schema) => ({
            parameterSchemaId: schema.id,
            quality: "Bad",
            samplingDataId: currentData.id!, // This exists, comes from the DB
            value: "",
          })),
      }));
      setAttachedFiles(
        currentData.images.map((imageKey) => ({
          name: imageKey,
          key: imageKey,
        }))
      );
    } else {
      setForm((state) => ({ ...state, initialForm }));
    }
    if (
      newSamplingDataViewParameters.isSuccess &&
      newSamplingDataViewParameters.data.length > 0
    ) {
      setForm((state) => ({
        ...state,
        parameters: newSamplingDataViewParameters.data.map((it) => ({
          ...it,
          value: String(it.value),
        })),
      }));
    } else if (newSamplingDataViewParameters.isUninitialized) {
      setForm((state) => ({
        ...state,
        parameters: parameterSchema
          .filter(
            (s) =>
              newSamplingDataView.selectedLocation &&
              config.parameterSchemaIds[
                newSamplingDataView.selectedLocation.category
              ].includes(s.id)
          )
          .map((schema) => ({
            parameterSchemaId: schema.id,
            quality: "Bad",
            samplingDataId: -1,
            value: "",
          })),
      }));
    }
  }, [
    setForm,
    newSamplingDataView,
    parameterSchema,
    config,
    newSamplingDataViewParameters,
  ]);

  const handleUploadData = async () => {
    if (newSamplingDataView.selectedLocation?.id) {
      const imageKeys: string[] = [];
      if (attachedFiles.length > 0) {
        const signedUrlsResponse = await postSignedUrlMutation(attachedFiles);
        if (signedUrlsResponse !== undefined) {
          const data = (signedUrlsResponse as { data: string[] }).data;
          for (let i = 0; i < data.length; i++) {
            if (attachedFiles[i].file) {
              // The attached files with undefined "file" are the images that exists.
              const putImagesToAWSResponse = await putFileAWS({
                signedUrl: data[i],
                contentType: attachedFiles[i].file?.type || "",
                file: attachedFiles[i].file!,
              });
              if (Object.keys(putImagesToAWSResponse).includes("data")) {
                imageKeys.push(attachedFiles[i].key || "none");
              } else {
                console.log("Something went wrong when attaching the files.");
              }
            } else {
              imageKeys.push(attachedFiles[i].key || "none");
            }
          }
        }
      }

      const updateDataResponse = await updateData({
        id: form.id,
        laboratoryId: form.laboratoryId,
        class: form.class,
        comment: form.comment,
        constructionWorksComment: form.constructionWorksComment,
        coordinates: {
          lat: +form.coordinates.lat,
          lon: +form.coordinates.lon,
        },
        inspectionDate: parseInt("" + form.inspectionDate.valueOf() / 1000),
        laboratoryDate: parseInt("" + form.laboratoryDate.valueOf() / 1000),
        images: Array.from(new Set(form.images.concat(imageKeys))),
        locationId: newSamplingDataView.selectedLocation.id,
        quality: form.quality,
        typeOfMonitoring: form.typeOfMonitoring,
        airTemperature: form.airTemperature,
        windSpeed: form.windSpeed,
        airHumidity: form.airHumidity,
        airPressure: form.airPressure,
        precipitation: form.precipitation,
      });

      if (Object.keys(updateDataResponse).includes("data")) {
        const updateParameterResponse = await updateParameter(
          form.parameters.map((it) => ({
            ...it,
            samplingDataId: (
              updateDataResponse as {
                data: SamplingData;
              }
            ).data.id!, // This id exists as it comes from the DB.
            value: it.value,
          }))
        );
        if (Object.keys(updateParameterResponse).includes("data")) {
          console.log("parameters ok");
        } else {
          console.log("Something went wrong when updating the parameters.");
        }
      } else {
        console.log("Something went wrong when updating the sampling data.");
      }
      console.log("updateDataResponse", updateDataResponse);
      newSamplingDataViewParameters.refetch();
    }
  };
  return {
    form,
    setForm,
    attachedFiles,
    setAttachedFiles,
    handleUploadData,
    updateParameterState,
    updateDataState,
    postSignedUrlMutationState,
    putFileAWSState,
  };
};
