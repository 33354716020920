import { DateTime } from "luxon";
import { SamplingData } from "../../../../types/SamplingData";
import { ChartDataXY } from "../components/LineChart";

function fromQualityStringToNumber(quality: string): number {
  if (quality === "Bad") {
    return 1;
  }
  if (quality === "Medium") {
    return 2;
  }
  if (quality === "Good") {
    return 3;
  }
  return 0;
}

export const getChartData = (
  originalData: SamplingData[]
): { green: ChartDataXY[]; red: ChartDataXY[]; yellow: ChartDataXY[] } => {
  const xyData = originalData.map((data) => {
    return {
      x: DateTime.fromSeconds(data.inspectionDate),
      y: fromQualityStringToNumber(data.quality),
    };
  });
  return {
    green: xyData.filter((it, index) => originalData[index].quality === "Good"),
    red: xyData.filter((it, index) => originalData[index].quality === "Bad"),
    yellow: xyData.filter(
      (it, index) => originalData[index].quality === "Medium"
    ),
  };
};
