import { Box, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import { Print } from "../common/print/Print";
import { useAppSelector } from "../data/store";
import { DetailAssetSummary } from "./Detail/DetailAssetSummary/DetailAssetSummary";
import DetailChart from "./Detail/DetailChart/DetailChart";
import { Filter } from "./Filter";
import { SamplingDataFilter } from "./ManageData/SamplingDataFilter";
import SamplingDataTable from "./ManageData/SamplingDataTable";
import { MapboxComponent } from "./MapboxComponent";

export const MapRoot: React.FC = () => {
  const appViewState = useAppSelector((state) => state.appView.value);

  const [showPrint, setShowPrint] = useState(false);
  const [isMoreThan1280] = useMediaQuery("(min-width: 1281px)");

  if (showPrint) {
    return <Print />;
  }

  return (
    <>
      {" "}
      <Box
        className="MapRoot dont-print"
        width="100%"
        height="100%"
        position="relative"
      >
        <MapboxComponent />
        <Grid
          gap="2rem"
          templateColumns={"repeat(10, 1fr)"}
          templateRows={"repeat(4,1fr)"}
          width="100%"
          height="100%"
          zIndex="1"
          pt={!isMoreThan1280 ? "1rem" : "2rem"}
          pb="6rem"
          paddingLeft={{ sm: "2rem", md: "7rem" }}
          paddingRight={{ sm: "2rem", md: "7rem" }}
        >
          {appViewState !== "detail" ? (
            <React.Fragment>
              <GridItem
                zIndex={4}
                colSpan={{ sm: 10, md: 2 }}
                rowSpan={{ sm: 1, md: 4 }}
                pointerEvents="none"
              >
                <Box pointerEvents="all">
                  {appViewState === "samplingDataTable" && (
                    <SamplingDataFilter />
                  )}
                  {appViewState !== "samplingDataTable" && <Filter />}
                </Box>
              </GridItem>

              {appViewState === "samplingDataTable" && (
                <GridItem
                  zIndex={4}
                  colSpan={{ sm: 10, md: 8 }}
                  rowSpan={{ sm: 3, md: 4 }}
                  height="100%"
                  pointerEvents="none"
                >
                  <Box pointerEvents="all" height="100%">
                    <SamplingDataTable />
                  </Box>
                </GridItem>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <GridItem
                zIndex={4}
                colSpan={{ sm: 10, md: 5 }}
                rowSpan={{ sm: 2, md: 4 }}
                pointerEvents="none"
                width="100%"
              >
                <Box pointerEvents="all" height={{ sm: "100%", md: "50%" }}>
                  <DetailChart setShowPrint={setShowPrint} />
                </Box>
              </GridItem>
              <GridItem
                zIndex={4}
                colSpan={{ sm: 10, md: 5 }}
                rowSpan={{ sm: 2, md: 4 }}
                pointerEvents="none"
              >
                <Box pointerEvents="all" height="100%">
                  <DetailAssetSummary />
                </Box>
              </GridItem>
            </React.Fragment>
          )}
        </Grid>
      </Box>
    </>
  );
};
