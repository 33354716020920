import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCognitoGroups, getExpirationDate } from "../common/auth/utils";

export interface AuthState {
  exp?: number;
  token?: string;
  groups: string[];
}

// Define the initial state using that type
const initialState: AuthState = {
  exp: undefined,
  token: undefined,
  groups: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<{ token: string }>) => {
      if (action.payload.token) {
        const groups = getCognitoGroups(action.payload.token);
        state.groups = groups;
      }
      state.token = action.payload.token;
      state.exp = getExpirationDate(action.payload.token);
    },
  },
});
