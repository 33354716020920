import { ImageType } from "./MapboxComponent";

export const imageMapping: Record<string, ImageType> = {
  Water: "circle",
  Air: "triangle",
  Soil: "square",
  Noise: "pentagon",
  Vibrations: "star",
  Groundwater: "hexagon",
};
