import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppView {
  value: "map" | "detail" | "samplingDataTable";
}

// Define the initial state using that type
const initialState: AppView = {
  value: "map",
};

export const appViewSlice = createSlice({
  name: "appView",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<AppView>) => {
      state.value = action.payload.value;
    },
  },
});
