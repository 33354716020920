import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import SpanSeparator from "../../../common/components/SpanSeparator";
import TrafficLight from "../../../common/components/TrafficLight";
import { capitalize } from "../../../common/utils/capitalize";
import { detailViewSlice } from "../../../data/detailViewSlice";
import { useAppDispatch, useAppSelector } from "../../../data/store";
import { Parameter } from "../../../types/Parameter";
import { ParameterSchema } from "../../../types/ParameterSchema";
import DetailsModal from "./components/DetailsModal";
import { qualityToColor } from "./qualityToColor";

interface IProps {}

const parseReference = (
  schema: ParameterSchema,
  reference: string | undefined
) => {
  if (schema.typeOfReference === "none") {
    return "";
  }
  if (!Number.isNaN(reference)) {
    if (
      Number(reference) === Number.MIN_VALUE ||
      Number(reference) === Number.MAX_VALUE
    ) {
      return "";
    }
    return reference;
  }

  if (reference !== undefined) {
    return reference;
  }

  return "";
};

export const DetailAssetSummary: React.FC<IProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatcher = useAppDispatch();
  const config = useAppSelector((state) => state.config.value);
  const mapFilter = useAppSelector((state) => state.mapFilter.value);
  const detailViewState = useAppSelector((state) => state.detailView);
  const parameterSchema = useAppSelector((state) => state.parameterSchema.list);

  return (
    <Box
      right="0"
      padding="2rem"
      borderRadius="0.4rem"
      h="100%"
      maxHeight="100%"
      bgColor="white"
      overflowX="hidden"
      w="100%"
      className="scroll-shadows"
    >
      {detailViewState.selectedLocation &&
      detailViewState.currentSamplingData ? (
        <>
          <Flex justify="space-between" mb={4}>
            <Box>
              <Text as="span" fontSize="small" color="primary.main">
                {DateTime.fromSeconds(
                  detailViewState.currentSamplingData.inspectionDate
                ).toFormat("dd LLL yyyy")}
              </Text>
              {config.classes[detailViewState.selectedLocation.category]
                .length > 1 && (
                <>
                  <SpanSeparator />
                  <Text as="span" fontSize="small" color="primary.main">
                    {detailViewState.currentSamplingData.class}
                  </Text>
                </>
              )}
            </Box>
            <Button variant="solidSecondary" h="1.5rem" onClick={onOpen}>
              + Info
            </Button>
          </Flex>

          <Grid templateColumns="repeat(4, 1fr)" gap={6} fontSize="0.875rem">
            <GridItem w="100%">
              <Text color="gray.500">Laboratory Id</Text>
              <Text>{detailViewState.currentSamplingData.laboratoryId}</Text>
            </GridItem>
            <GridItem w="100%">
              <Text color="gray.500">Sampled date</Text>
              <Text>
                {DateTime.fromSeconds(
                  detailViewState.currentSamplingData.inspectionDate
                ).toFormat("dd LLL yyyy")}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text color="gray.500">Entry date</Text>
              <Text>
                {DateTime.fromSeconds(
                  detailViewState.currentSamplingData.laboratoryDate
                ).toFormat("dd LLL yyyy")}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text color="gray.500">Construction works</Text>
              <Text>
                {detailViewState.currentSamplingData.constructionWorksComment}
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text color="gray.500">Air temperature</Text>
              <Text>
                {detailViewState.currentSamplingData.airTemperature} ºC
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text color="gray.500">Windspeed</Text>
              <Text>{detailViewState.currentSamplingData.windSpeed} m/s</Text>
            </GridItem>
            <GridItem w="100%">
              <Text color="gray.500">Air humidity</Text>
              <Text>{detailViewState.currentSamplingData.airHumidity} %</Text>
            </GridItem>
            <GridItem w="100%">
              <Text color="gray.500">Air pressure</Text>
              <Text>{detailViewState.currentSamplingData.airPressure} hPa</Text>
            </GridItem>
            <GridItem w="100%">
              <Text color="gray.500">Precipitation</Text>
              <Text>
                {detailViewState.currentSamplingData.precipitation} mm
              </Text>
            </GridItem>
            <GridItem w="100%">
              <Text color="gray.500">Type of monitoring</Text>
              <Text>
                {capitalize(
                  detailViewState.currentSamplingData.typeOfMonitoring
                )}
              </Text>
            </GridItem>
          </Grid>
          <RadioGroup
            name="parameter"
            value={detailViewState.parameterNameOrId}
            onChange={(e) => {
              if (
                detailViewState.selectedLocation &&
                detailViewState.selectedLocation.id
              ) {
                dispatcher(
                  detailViewSlice.actions.setParameterId({
                    parameterId: e,
                    dateUnixTimestamp: mapFilter.inspectionDate,
                  })
                );
              }
            }}
          >
            <Table variant="simple" fontSize="small">
              <Thead>
                <Tr>
                  <Th fontWeight="400" fontSize="0.75rem">
                    Parameter
                  </Th>
                  <Th fontWeight="400" fontSize="0.75rem">
                    Ref.
                  </Th>
                  <Th fontWeight="400" fontSize="0.75rem">
                    Value
                  </Th>
                  <Th fontWeight="400" fontSize="0.75rem">
                    Unit
                  </Th>
                  <Th fontWeight="400" fontSize="0.75rem" textAlign="right">
                    Quality
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr
                  backgroundColor={
                    detailViewState.parameterNameOrId === "Overall performance"
                      ? "gray.tr"
                      : "white"
                  }
                >
                  <Td colSpan={2}>
                    <Radio
                      size="sm"
                      colorScheme="gray"
                      value="Overall performance"
                    >
                      Overall performance
                    </Radio>
                  </Td>
                  <Td colSpan={2} fontWeight="bold">
                    {detailViewState.currentSamplingData?.quality}
                  </Td>
                  <Td>
                    <Box w="fit-content" ml="auto" mr="0">
                      <TrafficLight
                        color={qualityToColor(
                          detailViewState.currentSamplingData.quality
                        )}
                        outline={
                          detailViewState.parameterNameOrId ===
                          "Overall performance"
                            ? true
                            : false
                        }
                      />
                    </Box>
                  </Td>
                </Tr>
                {detailViewState.currentSamplingData &&
                  detailViewState.parameterListTable.map(
                    (it: Parameter, index) => {
                      const schema = parameterSchema.find(
                        (pSchema) => it.parameterSchemaId === pSchema.id
                      );
                      console.log(
                        detailViewState.currentSamplingData,
                        parameterSchema,
                        schema?.reference,
                        detailViewState.currentSamplingData?.class
                      );
                      if (schema) {
                        return (
                          <Tr
                            key={index}
                            backgroundColor={
                              detailViewState.parameterNameOrId ===
                              schema.id + ""
                                ? "gray.tr"
                                : "white"
                            }
                          >
                            <Td>
                              <Radio
                                size="sm"
                                colorScheme="red"
                                value={schema.id + ""}
                              >
                                {schema.name}
                              </Radio>
                            </Td>
                            <Td>
                              {parseReference(
                                schema,
                                detailViewState.currentSamplingData &&
                                  schema.reference[
                                    detailViewState.currentSamplingData.class
                                  ].toString()
                              )}
                            </Td>
                            <Td fontWeight="bold">{it.value}</Td>
                            <Td>{schema.unit}</Td>
                            <Td>
                              <Box w="fit-content" ml="auto" mr="0">
                                <TrafficLight
                                  color={qualityToColor(it.quality)}
                                  outline={
                                    detailViewState.parameterNameOrId ===
                                    schema.id + ""
                                      ? true
                                      : false
                                  }
                                />
                              </Box>
                            </Td>
                          </Tr>
                        );
                      } else {
                        return <></>;
                      }
                    }
                  )}
              </Tbody>
            </Table>
          </RadioGroup>

          <DetailsModal
            location={detailViewState.selectedLocation}
            data={detailViewState.currentSamplingData}
            isOpen={isOpen}
            onClose={onClose}
          />
        </>
      ) : (
        <Spinner />
      )}
    </Box>
  );
};
