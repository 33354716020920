import jwt from "jsonwebtoken";
import { DateTime } from "luxon";

export const getEmail = (token: string) => {
  const decoded = jwt.decode(token.replace("Bearer ", ""));
  return typeof decoded === "object" ? decoded?.email || "" : "";
};

export const getExpirationDate = (token: string): number | undefined => {
  const decoded = jwt.decode(token.replace("Bearer ", ""));
  if (typeof decoded === "object") {
    const exp = decoded?.exp;
    if (typeof exp === "number") {
      return DateTime.fromSeconds(exp).toUnixInteger();
    }
  }
  return undefined;
};

export const getCognitoGroups = (token: string): string[] => {
  const decoded = jwt.decode(token.replace("Bearer ", ""));
  const checkIsObject = typeof decoded === "object";
  if (checkIsObject && decoded) {
    const groups = decoded["cognito:groups"];
    return groups;
  }
  return [];
};

export const isArupEmail = (email: string) => /^.*@arup\.com$/.test(email);
