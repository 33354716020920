import { ComponentStyleConfig } from "@chakra-ui/react";
import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import { PartsStyleObject } from "@chakra-ui/theme-tools";

export const baseStyle: PartsStyleObject<typeof parts> = {
	field: {
		fontSize: "small",
		border: "1px solid",
		borderColor: "gray.100",
		borderRadius: "0.5rem",
		padding: "0.5rem 1rem",
	},
};

const componentOverride: ComponentStyleConfig = {
	parts: ["field"],
	baseStyle: baseStyle,
	defaultProps: {
		variant: "",
		size: "",
	},
};

export default componentOverride;
