import { ComponentStyleConfig } from "@chakra-ui/react";
import Input, { baseStyle as inputBaseStyle } from "./Input";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {
    h: "2rem",
    field: {
      _disabled: { opacity: 0.5 },
      ...inputBaseStyle.field,
    },
  },
  defaultProps: {
    ...Input.defaultProps,
  },
};

export default componentOverride;
