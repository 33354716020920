import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React from "react";

interface InputExtendedProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onReset: () => void;
  value: string | number;
  label: string;
}

export const InputExtended: React.FC<InputExtendedProps> = (props) => {
  return (
    <Box width="100%">
      <Text marginBottom="0.5rem">{props.label}</Text>
      <InputGroup>
        <Input
          placeholder="None"
          height="2rem"
          type="text"
          value={props.value}
          onChange={props.onChange}
        />
        <InputRightElement
          width="2.5rem"
          height="2rem"
          onClick={props.onReset}
          children={<CloseIcon color="gray.200" />}
        />
      </InputGroup>
    </Box>
  );
};
