export type EnvConfigurationType = {
  AWS_POOL_ID: string;
  AWS_REGION: string;
  AWS_WEB_CLIENT_ID: string;
  AWS_COGNITO_DOMAIN: string;
  AWS_AZURE_PROVIDER_ID: string;
  API_URL: string;
  APP_NAME: string;
  MAPBOX_ACCESS_TOKEN: string;
};

export const envConfig: EnvConfigurationType = {
  AWS_POOL_ID: "eu-west-1_ehMZjDbJz",
  AWS_REGION: "eu-west-1",
  AWS_WEB_CLIENT_ID: "7l62g1812puqj3mn9c5i7875v2",
  AWS_COGNITO_DOMAIN: "dhubauth.auth.eu-west-1.amazoncognito.com",
  AWS_AZURE_PROVIDER_ID: "AzureAD",
  API_URL: "https://moravaapi.dhub.arup.com/api",
  APP_NAME: "Morava Road",
  MAPBOX_ACCESS_TOKEN:
    "pk.eyJ1IjoiZGh1YiIsImEiOiJjbDN5M2l3djMwMGJtM3BxZ24yOHB3OW92In0.lsvg9O8GcY2o4LXiCxaTew",
};
