import { Flex, IconButton, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import { FiTrash2 } from "react-icons/fi";
import { IAttachedFile } from "../../types/IAttachedFile";

interface IProps {
  attachedFiles: IAttachedFile[];
  setAttachedFiles: (attachedFiles: IAttachedFile[]) => void;
}

export const AttachedFilesList: React.FC<IProps> = (props) => {
  const { attachedFiles, setAttachedFiles } = props;

  const dettachFile = (file: IAttachedFile) => {
    const editedAttachedFiles = [...attachedFiles];
    const index = editedAttachedFiles.findIndex((aF) => aF === file);
    editedAttachedFiles.splice(index, 1);
    setAttachedFiles(editedAttachedFiles);
  };

  return (
    <List>
      {attachedFiles.map((file, index) => (
        <ListItem key={`attachedFile_${index}`}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text
              fontSize="small"
              height="1.4rem"
              width="70%"
              display="inline-block"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {file.name} 
            </Text>
            <IconButton
              aria-label="De-attach file"
              icon={<FiTrash2 />}
              variant="noBackground"
              onClick={() => dettachFile(file)}
              height="1rem"
              width="1rem"
            />
          </Flex>
        </ListItem>
      ))}
    </List>
  );
};
